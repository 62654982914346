import { atom, selector } from "recoil";

import { defaultThemeName, themes } from "../../constants/themes";
import { persistAtom } from "../../helpers/state";

export const themeNameState = atom({
  key: "themeNameState",
  default: defaultThemeName,
  effects_UNSTABLE: [persistAtom],
});

export const themeState = selector({
  key: "themeState",
  get: ({ get }) => themes[get(themeNameState)],
});
