import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLogInForm } from "../../hooks/forms/auth/useLogInForm";

export function LogInForm() {
  const { t } = useTranslation();
  const state = useLogInForm();

  return (
    <Form onSubmit={state.onSubmit} validated={state.isValidated}>
      {!!state.error && (
        <Alert variant="danger">{state.error.toString()}</Alert>
      )}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>{t("forms.auth.email.label")}</Form.Label>
        <Form.Control
          required
          autoFocus
          type="email"
          value={state.values.email}
          placeholder="bob@gmail.com"
          onChange={state.onInputChange("email")}
        />
      </Form.Group>
      <Form.Group controlId="password" className="mb-3">
        <Form.Label>{t("forms.auth.password.label")}</Form.Label>
        <Form.Control
          required
          minLength={8}
          type="password"
          value={state.values.password}
          placeholder="************"
          onChange={state.onInputChange("password")}
        />
        <Form.Text muted>{t("forms.auth.password.help")}</Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={state.isLoading}>
        {state.isLoading ? t("app.isLoading") : t("auth.logIn")}
      </Button>
    </Form>
  );
}
