import { useTranslation } from "react-i18next";

import { useForm } from "../../shared/useForm";
import { authFormState } from "../../../state/forms/auth";
import { logIn } from "../../../collections/users";
import { useAuthSuccess } from "./useAuthSuccess";

export function useLogInForm() {
  const { t } = useTranslation();

  const onSuccess = useAuthSuccess({
    title: t("notifications.loggedIn.title"),
    message: t("notifications.loggedIn.message"),
    autoHideAfterSeconds: 10,
  });

  return useForm(authFormState, logIn, onSuccess);
}
