import { useRecoilValue } from "recoil";

import { useGameProgress } from "./useGameProgress";
import { getElapsedTime } from "../../helpers/timer";
import { startedAtState } from "../../state/game/startedAt";
import { endedAtState } from "../../state/game/endedAt";
import { rulesState } from "../../state/game/rules";

export function useGameResultsCard() {
  const progress = useGameProgress();
  const rules = useRecoilValue(rulesState);
  const startedAt = useRecoilValue(startedAtState);
  const endedAt = useRecoilValue(endedAtState);

  const elapsedTime = getElapsedTime(startedAt, endedAt, rules.timer);
  const isSuccess = progress.count === progress.total;

  return {
    progress,
    elapsedTime,
    isSuccess,
    endedAt,
  };
}
