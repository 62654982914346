import { useTranslation } from "react-i18next";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useResponsiveRoomScores } from "../../../hooks/rooms/useResponsiveRoomScores";
import { RoomScores } from "./RoomScores";

import "./ResponsiveRoomScores.scss";

export function ResponsiveRoomScores({ ...props }) {
  const { t } = useTranslation();
  const state = useResponsiveRoomScores();

  return (
    <div className="ResponsiveRoomScores">
      {state.isSmall ? (
        <>
          <Button
            variant="light"
            tabIndex={0}
            className="OpenRoomScores"
            size="lg"
            onClick={state.onOpenScores}
          >
            <FontAwesomeIcon icon={faAnglesRight} fixedWidth />
          </Button>
          <Offcanvas
            show={state.areScoresOpen}
            onHide={state.onCloseScores}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{t("multi.rooms.roomScores")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <RoomScores {...props} />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        <div className="AppContentTopLeft">
          <RoomScores {...props} />
        </div>
      )}
    </div>
  );
}
