import clsx from "clsx";
import { Alert, Row } from "react-bootstrap";

import { useProfileContinentsAchievements } from "../../hooks/profiles/useProfileContinentsAchievements";
import { Spinner } from "../shared/Spinner";

export function AchievementsContinentsBadges({ mode }) {
  const state = useProfileContinentsAchievements(mode);

  if (state.error) {
    return <Alert variant="danger">{state.error.toString()}</Alert>;
  }

  if (state.isLoading) {
    return <Spinner />;
  }

  const unlocked = (continentCode) =>
    state.foundNames.has(state.getName(continentCode));

  return (
    <Row className="justify-content-center">
      {state.continentCodes.map((continentCode, i) => (
        <img
          alt={
            unlocked(continentCode)
              ? `Achievement unlocked`
              : "Achievement locked"
          }
          key={continentCode}
          src={`/badges/${state.getName(continentCode)}.png`}
          className={clsx("mb-2", unlocked(continentCode) && "unlocked")}
        />
      ))}
    </Row>
  );
}
