import { useCallback, useRef, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { toJpeg } from "html-to-image";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";

import {
  areResultsSavableState,
  areResultsSavedState,
  areResultsSavingState,
} from "../../state/users/results";
import { endedAtState } from "../../state/game/endedAt";
import { themeState } from "../../state/game/theme";
import { activeCodeState } from "../../state/game/activeCode";
import { sleep } from "../../helpers/sleep";
import { useSaveResults } from "../users/useSaveResults";
import { auth } from "../../firebase";
import { shouldRequestAuthState } from "../../state/users/auth";
import { AuthNextPage } from "../../constants/auth";
import { foundCodesState } from "../../state/game/foundCodes";

export function useGameResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const sharableResultsRef = useRef();
  const endedAt = useRecoilValue(endedAtState);
  const theme = useRecoilValue(themeState);
  const foundCodes = useRecoilValue(foundCodesState);
  const areResultsSavable = useRecoilValue(areResultsSavableState);
  const areResultsSaving = useRecoilValue(areResultsSavingState);
  const areResultsSaved = useRecoilValue(areResultsSavedState);
  const setShouldRequestAuth = useSetRecoilState(shouldRequestAuthState);
  const resetActiveCodeState = useResetRecoilState(activeCodeState);
  const [isLoading, setLoading] = useState(false);
  const { saveResults } = useSaveResults();

  const onShare = useCallback(async () => {
    try {
      setLoading(true);
      resetActiveCodeState();
      await sleep();

      const options = { cacheBust: true, backgroundColor: theme.waterColor };
      const href = await toJpeg(sharableResultsRef.current, options);
      const link = document.createElement("a");
      link.download = `www.geoquiz.live-${endedAt}.jpg`;
      link.href = href;
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [endedAt, resetActiveCodeState, theme.waterColor]);

  const onSaveResults = useCallback(() => {
    if (user?.uid) {
      saveResults(endedAt, foundCodes).catch(console.error);
    } else {
      setShouldRequestAuth({ value: true, nextPage: null });
    }
  }, [endedAt, foundCodes, saveResults, setShouldRequestAuth, user?.uid]);

  const onTrackProgress = useCallback(() => {
    if (user?.uid) {
      navigate(`/profiles/${user?.uid}`, {
        state: { origin: location.pathname },
      });
    } else {
      setShouldRequestAuth({ value: true, nextPage: AuthNextPage.myProfile });
    }
  }, [location.pathname, navigate, setShouldRequestAuth, user?.uid]);

  return {
    endedAt,
    theme,
    sharableResultsRef,
    isLoading,
    isSaveDisabled: !areResultsSavable || areResultsSaving || areResultsSaved,
    areResultsSaving,
    areResultsSaved,
    onShare,
    onSaveResults,
    onTrackProgress,
  };
}
