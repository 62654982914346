import { useEffect, useState } from "react";
import createActivityDetector from "activity-detector";

export function useTrackUserActivity() {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const activityDetector = createActivityDetector();

    activityDetector.on("active", () => setIsActive(true));
    activityDetector.on("idle", () => setIsActive(false));

    return () => {
      activityDetector.stop();
    };
  }, []);

  return isActive;
}
