import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";

import { unsavedResultsState } from "../../state/users/results";
import { useSaveResults } from "./useSaveResults";
import { auth } from "../../firebase";

export function useAutosaveScores() {
  const [user] = useAuthState(auth);
  const [unsavedResults, setUnsavedResults] =
    useRecoilState(unsavedResultsState);
  const { writeResults, onResultsSaved } = useSaveResults();

  useEffect(() => {
    if (!user?.uid || !user?.displayName || !unsavedResults.length) {
      return;
    }

    async function run() {
      let hasBestScore = false;

      for (const partialData of unsavedResults) {
        const isBestScore = await writeResults(partialData);

        if (isBestScore) {
          hasBestScore = true;
        }
      }

      if (hasBestScore) {
        onResultsSaved();
      }

      setUnsavedResults([]);
    }

    run().catch(console.error);
  }, [
    user?.uid,
    unsavedResults,
    user?.displayName,
    writeResults,
    setUnsavedResults,
    onResultsSaved,
  ]);
}
