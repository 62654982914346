import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";

import { antarcticaLayerUrl } from "../../../constants/globe";
import { qualityState } from "../../../state/game/settings";
import { sleep } from "../../../helpers/sleep";
import { antarcticaRendererState } from "../../../state/layers/antarctica/renderer";

export function useAntarcticLayer(refs) {
  const quality = useRecoilValue(qualityState);
  const antarcticaRenderer = useRecoilValue(antarcticaRendererState);
  const [isAntarcticaLayerReady, setAntarcticaLayerReady] = useState(false);

  useEffect(() => {
    if (refs.current.antarcticaLayer) {
      refs.current.map.remove(refs.current.antarcticaLayer);
      refs.current.antarcticaLayer.destroy();
      setAntarcticaLayerReady(false);
    }

    refs.current.antarcticaLayer = new GeoJSONLayer({
      url: antarcticaLayerUrl(quality),
      visible: false,
      outFields: ["*"],
    });

    refs.current.antarcticaLayer.on("layerview-create", () => {
      setAntarcticaLayerReady(true);
    });

    refs.current.map.add(refs.current.antarcticaLayer);
  }, [quality, refs]);

  useEffect(() => {
    sleep(1).then(() => {
      refs.current.antarcticaLayer.renderer = antarcticaRenderer;
      refs.current.antarcticaLayer.visible = isAntarcticaLayerReady;
    });
  }, [antarcticaRenderer, isAntarcticaLayerReady, refs]);

  return { isAntarcticaLayerReady };
}
