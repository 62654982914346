import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { useSettings } from "../../hooks/toolbar/useSettings";
import { SettingsForm } from "../../forms/SettingsForm";

export function Settings() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { isResetDisabled, onReset } = useSettings();

  const onToggle = () => setShow((show) => !show);
  const onClose = () => setShow(false);

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      show={show}
      onToggle={onToggle}
      overlay={
        <Popover id="settings-popover">
          <Popover.Body>
            <SettingsForm />
            <div className="d-flex gap-1">
              <Button
                tabIndex={0}
                variant="danger"
                className="mx-1"
                disabled={isResetDisabled}
                onClick={onReset}
              >
                {t("app.reset")}
              </Button>
              <Button
                tabIndex={0}
                variant="primary"
                className="mx-1"
                onClick={onClose}
              >
                {t("app.save")}
              </Button>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <Button variant="light" tabIndex={0}>
        <FontAwesomeIcon icon={faCog} fixedWidth />
      </Button>
    </OverlayTrigger>
  );
}
