import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/es";

export function FormattedDate({ value, inputFormat, outputFormat }) {
  const { i18n } = useTranslation();
  return dayjs
    .utc(value, inputFormat)
    .locale(i18n.language)
    .format(outputFormat);
}
