import { useTranslation } from "react-i18next";
import { Badge, ProgressBar } from "react-bootstrap";
import { useRecoilValue } from "recoil";

import { useGameProgress } from "../../../hooks/game/useGameProgress";
import { rulesState } from "../../../state/game/rules";

import "./GameProgress.scss";

export function GameProgress() {
  const { t } = useTranslation();
  const rules = useRecoilValue(rulesState);
  const { progress, percentage, count, total } = useGameProgress();

  if (!count) {
    return null;
  }

  const variant = progress < 50 ? "danger" : "primary";
  const text = variant === "warning" ? "dark" : "light";

  return (
    <div className="GameProgress">
      <ProgressBar now={progress} variant={variant} />
      <Badge pill bg={variant} text={text} className="Info">
        {t(`game.progress.${rules.mode}`, {
          count,
          total,
          percentage,
        })}
      </Badge>
    </div>
  );
}
