import { useRecoilValue, useResetRecoilState } from "recoil";

import { autoZoomState } from "../../state/game/settings";

export function useSettings() {
  const autoZoom = useRecoilValue(autoZoomState);
  const onReset = useResetRecoilState(autoZoomState);

  return { isResetDisabled: autoZoom, onReset };
}
