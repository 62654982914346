import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { profilesCollection } from "../../collections/profiles";

export function useProfile() {
  const { id } = useParams();
  const [profile, isLoading, error] = useDocumentData(
    doc(profilesCollection, id)
  );

  return { isLoading, error, profile };
}
