import { useCallback } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import { FormattedTime } from "../../shared/FormattedTime";
import { useUserInfo } from "../../../hooks/users/useUserInfo";
import { Username } from "../../user/Username";
import { auth } from "../../../firebase";
import { deleteRoom } from "../../../collections/rooms";
import { useNotifications } from "../../../hooks/notifications/useNotifications";

export function ListRoomRow({ room }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const url = `/multi/rooms/${room.id}`;
  const userInfo = useUserInfo(room.createdBy, url);
  const { notify } = useNotifications();

  const onDelete = useCallback(async () => {
    try {
      await deleteRoom(room.id);

      notify({
        title: t("notifications.roomDeleted.title"),
        message: t("notifications.roomDeleted.message"),
      });
    } catch (error) {
      console.error(error);
    }
  }, [notify, room.id, t]);

  return (
    <tr>
      <td>{room.name}</td>
      <td>
        <Username
          id={room.createdBy}
          value={room.createdByUsername}
          {...userInfo}
        />
      </td>
      <td>
        <FormattedTime value={room.createdAt} />
      </td>
      <td className="col-sm-1 text-nowrap">
        <Button
          tabIndex={0}
          onClick={() =>
            navigate(url, { state: { origin: location.pathname } })
          }
        >
          {t("multi.rooms.join.join")}
        </Button>
        {room.createdBy === user?.uid && (
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={
              <Popover id={`delete-room-${room.id}`}>
                <Popover.Header as="h3">
                  {t("app.confirmationMessage")}
                </Popover.Header>
                <Popover.Body>
                  <Button
                    tabIndex={0}
                    className="w-100"
                    variant="danger"
                    onClick={onDelete}
                  >
                    {t("app.delete")}
                  </Button>
                </Popover.Body>
              </Popover>
            }
          >
            <Button tabIndex={0} variant="danger" className="ms-1">
              {t("app.delete")}
            </Button>
          </OverlayTrigger>
        )}
      </td>
    </tr>
  );
}
