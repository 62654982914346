import { selector } from "recoil";

import { allCountriesPerCode } from "../../constants/datasets/countries";
import { continentCodes } from "../../constants/datasets/continents";
import { allCodesState } from "./allCodes";

export const totalResultsPerContinentState = selector({
  key: "totalResultsPerContinentState",
  get: ({ get }) => {
    const allCodes = get(allCodesState);

    return new Map(
      continentCodes.map((continentCode) => {
        const codes = Array.from(allCodes)
          .map((countryCode) => allCountriesPerCode.get(countryCode))
          .filter((country) => country.continent === continentCode).length;

        return [continentCode, codes];
      })
    );
  },
});
