import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card } from "react-bootstrap";

import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";

export function ErrorPage({ message = null, hasWebgl = true }) {
  const location = useLocation();
  const { t } = useTranslation();
  useDocumentTitle(t("app.error"));

  const error = location.state?.error;
  message = error?.message || error?.toString() || message;

  return (
    <div className="AppContentTop">
      <Card bg="danger" text="light">
        <Card.Header>{t("app.error")}</Card.Header>
        <Card.Body>
          {hasWebgl && <HomeButton className="float-end" />}
          <Card.Text>{message}</Card.Text>
          {!hasWebgl && (
            <>
              <Button
                variant="light"
                href="https://www.lifewire.com/hardware-acceleration-in-chrome-4125122"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("webgl.enable")}
              </Button>
              <HomeButton className="ms-2" />
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

function HomeButton({ className = null }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      variant="light"
      className={className}
      onClick={() => navigate("/", { state: { error: null } })}
    >
      {t("app.home")}
    </Button>
  );
}
