import { selector } from "recoil";

import { statusState } from "../game/status";
import { GameStatus } from "../../constants/game";
import { foundCodesState } from "../game/foundCodes";
import { allCodesState } from "./allCodes";

export const allResultCodesState = selector({
  key: "allResultCodesState",
  get: ({ get }) => {
    const allCodes = get(allCodesState);
    const foundCodes = get(foundCodesState);
    const gameStatus = get(statusState);

    const hasGameEnded = gameStatus === GameStatus.ended;
    const codes = [];

    allCodes.forEach((code) => {
      if (hasGameEnded || foundCodes.has(code)) {
        codes.push(code);
      }
    });

    return codes;
  },
});
