import { Badge, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Username } from "../user/Username";
import { FormattedTime } from "../shared/FormattedTime";
import { useProfileHeader } from "../../hooks/profiles/useProfileHeader";

import "./ProfileHeader.scss";

export function ProfileHeader({ profile, userInfo }) {
  const { t } = useTranslation();
  const state = useProfileHeader(userInfo);

  return (
    <ListGroup horizontal={!state.isSmall} className="ProfileHeader">
      <ListGroup.Item>
        <Username
          id={profile.createdBy}
          value={profile.username}
          clickable={false}
          flag={false}
          maxWidth={false}
          {...userInfo}
        />
      </ListGroup.Item>
      {!!userInfo.country && (
        <ListGroup.Item>
          <small>
            <span className={`fi fi-${state.flagCode}`} /> {state.countryName}
          </small>
        </ListGroup.Item>
      )}
      <ListGroup.Item>
        <small>
          {t("pages.profile.accountCreated")} <FormattedTime value={profile.createdAt} />
        </small>
      </ListGroup.Item>
      {state.totalGames >= 0 && (
        <ListGroup.Item>
          <small>
            <Badge pill>{state.totalGames}</Badge>{" "}
            {t("pages.profile.games", { count: state.totalGames })}
          </small>
        </ListGroup.Item>
      )}
      {state.totalAchievements >= 0 && (
        <ListGroup.Item>
          <small>
            <Badge pill>{state.totalAchievements}/50</Badge>{" "}
            {t("pages.profile.achievements", {
              count: state.totalAchievements,
            })}
          </small>
        </ListGroup.Item>
      )}
    </ListGroup>
  );
}
