import { Toast, ToastContainer } from "react-bootstrap";

import { useNotifications } from "../../hooks/notifications/useNotifications";
import { FormattedTime } from "../shared/FormattedTime";

export function Notifications() {
  const { notifications, onClose } = useNotifications();

  return (
    <ToastContainer position="bottom-end">
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          onClose={() => onClose(notification.id)}
          delay={notification.delay}
          autohide={notification.autoHide}
          bg={notification.variant}
          className={`text-bg-${notification.variant}`}
        >
          <Toast.Header>
            <strong className="me-auto">{notification.title}</strong>
            <small className="text-muted">
              <FormattedTime value={notification.createdAt} />
            </small>
          </Toast.Header>
          <Toast.Body>{notification.message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
}
