import { useTranslation } from "react-i18next";

import { ErrorPage } from "../../pages/ErrorPage";
import { useRoom } from "../../hooks/rooms/useRoom";
import { LoadingPage } from "../../pages/LoadingPage";
import { RoomLoaded } from "./shared/RoomLoaded";

export function Room() {
  const { t } = useTranslation();
  const { isLoading, error, room, user } = useRoom();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage message={error.toString()} />;
  }

  if (!room) {
    return <ErrorPage message={t("multi.rooms.lobby.notFound")} />;
  }

  return <RoomLoaded room={room} user={user} />;
}
