import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import SceneView from "@arcgis/core/views/SceneView";
import VirtualLighting from "@arcgis/core/views/3d/environment/VirtualLighting";
import { useNavigate } from "react-router-dom";

import { activeCodeState } from "../../state/game/activeCode";
import { useGoTo } from "./useGoTo";
import { allCountriesPerCode } from "../../constants/datasets/countries";
import { autoZoomState } from "../../state/game/settings";

export function useSceneView(containerRef, refs) {
  const navigate = useNavigate();
  const autoZoom = useRecoilValue(autoZoomState);
  const [activeCode, setActiveCode] = useRecoilState(activeCodeState);
  const goTo = useGoTo(refs);

  useEffect(() => {
    refs.current.sceneView = new SceneView({
      container: containerRef.current,
      map: refs.current.map,
      zoom: 3,
      environment: {
        lighting: new VirtualLighting(),
      },
    });

    refs.current.sceneView.on("click", (event) => {
      refs.current.sceneView.hitTest(event).then(({ results }) => {
        if (!results.length) {
          setActiveCode(null);
          return;
        }

        const result = results[0];

        switch (result.graphic.layer) {
          case refs.current.antarcticaLayer: {
            const { longitude, latitude, zoom } = result.graphic.attributes;
            setActiveCode(null);
            goTo([longitude, latitude], zoom);
            break;
          }
          case refs.current.countriesLayer:
          case refs.current.citiesLayer: {
            const { code } = result.graphic.attributes;
            setActiveCode(code);
            break;
          }
          default:
            break;
        }
      });
    });

    refs.current.sceneView.catch((error) => {
      if (error.name.includes("webgl")) {
        navigate("/", { state: { error } });
      } else {
        console.error(error);
      }
    });
  }, [containerRef, goTo, navigate, refs, setActiveCode]);

  useEffect(() => {
    if (activeCode) {
      const activeCountry = allCountriesPerCode.get(activeCode);
      goTo(activeCountry.center, autoZoom ? activeCountry.zoom : null);
    }
  }, [activeCode, goTo, autoZoom]);
}
