import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useState } from "react";

export function useForm(
  valuesRecoilState,
  submit,
  onSuccess,
  resetOnSuccess = true
) {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState(null);
  const [values, setValues] = useRecoilState(valuesRecoilState);
  const resetValues = useResetRecoilState(valuesRecoilState);

  const onInputChange = useCallback(
    (field) => (event) =>
      setValues((values) => ({ ...values, [field]: event.target.value })),
    [setValues]
  );

  const onSelectChange = useCallback(
    (field) =>
      ({ value }) =>
        setValues((values) => ({ ...values, [field]: value })),
    [setValues]
  );

  const onSwitchChange = useCallback(
    (field) => (event) =>
      setValues((values) => ({ ...values, [field]: event.target.checked })),
    [setValues]
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const form = event.currentTarget;

      try {
        setError(null);
        setIsLoading(true);
        setIsValidated(true);

        if (form.checkValidity() === false) {
          return;
        }

        const result = await submit(values);
        onSuccess(result);
        resetOnSuccess && resetValues();
      } catch (error) {
        setError(error?.message.replace("Firebase: ", "") || error);
      } finally {
        setIsLoading(false);
      }
    },
    [onSuccess, resetOnSuccess, resetValues, submit, values]
  );

  return {
    isLoading,
    isValidated,
    error,
    values,
    onInputChange,
    onSelectChange,
    onSwitchChange,
    onSubmit,
  };
}
