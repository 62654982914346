import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DifficultyRule, ModeRule } from "../constants/rules";
import { extendedCodes, mainCodes } from "../constants/datasets/countries";

export function RulesFilterForm({ value, onSelectChange, onSwitchChange }) {
  const { t } = useTranslation();

  const totalMainCodes = mainCodes.size;
  const totalExtendedCodes = extendedCodes.size;

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Form.Group controlId="mode" className="mb-3">
            <Form.Label>{t("forms.rules.mode.label")}</Form.Label>
            <Form.Select value={value.mode} onChange={onSelectChange("mode")}>
              <option value={ModeRule.mainCountries}>
                {t("forms.rules.mode.options.mainCountries", {
                  count: totalMainCodes,
                })}
              </option>
              <option value={ModeRule.extendedTerritories}>
                {t("forms.rules.mode.options.extendedTerritories", {
                  count: totalExtendedCodes,
                })}
              </option>
              <option value={ModeRule.capitalCities}>
                {t("forms.rules.mode.options.capitalCities", {
                  count: totalMainCodes,
                })}
              </option>
              <option value={ModeRule.extendedCities}>
                {t("forms.rules.mode.options.extendedCities", {
                  count: totalExtendedCodes,
                })}
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="difficulty" className="mb-3">
            <Form.Label>{t("forms.rules.difficulty.label")}</Form.Label>
            <Form.Select
              value={value.difficulty}
              onChange={onSelectChange("difficulty")}
            >
              <option value={DifficultyRule.visibleBorders}>
                {t("forms.rules.difficulty.options.visibleBorders")}
              </option>
              <option value={DifficultyRule.hiddenBorders}>
                {t("forms.rules.difficulty.options.hiddenBorders")}
              </option>
              <option value={DifficultyRule.hiddenTerritories}>
                {t("forms.rules.difficulty.options.hiddenTerritories")}
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Check
            id="timer"
            type="switch"
            label={t("forms.rules.timer.label")}
            checked={value.timer}
            onChange={onSwitchChange("timer")}
          />
          <Form.Check
            id="allowTypos"
            type="switch"
            label={t("forms.rules.allowTypos.label")}
            checked={value.allowTypos}
            onChange={onSwitchChange("allowTypos")}
          />
          <Form.Check
            id="allowInformalNames"
            type="switch"
            label={t("forms.rules.allowInformalNames.label")}
            checked={value.allowInformalNames}
            onChange={onSwitchChange("allowInformalNames")}
          />
          <Form.Check
            id="allowAbbreviations"
            type="switch"
            label={t("forms.rules.allowAbbreviations.label")}
            checked={value.allowAbbreviations}
            onChange={onSwitchChange("allowAbbreviations")}
          />
        </Col>
      </Row>
    </Form>
  );
}
