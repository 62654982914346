import { useRoomPlayers } from "../../../hooks/rooms/useRoomPlayers";
import { RoomStartedOrEnded } from "./RoomStartedOrEnded";
import { RoomLobby } from "../notStarted/RoomLobby";
import { RoomChat } from "./RoomChat";

export function RoomLoaded({ room, user }) {
  const playersValues = useRoomPlayers(room, user);
  const props = { room, user, playersValues };

  return (
    <>
      {!!playersValues.player && (
        <div className="AppContentBottomRight">
          <RoomChat {...props} />
        </div>
      )}
      {room.startedAt ? (
        <RoomStartedOrEnded {...props} />
      ) : (
        <RoomLobby {...props} />
      )}
    </>
  );
}
