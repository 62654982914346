import { addDoc, collection } from "firebase/firestore";

import { converter } from "../helpers/collections";
import { profilesCollection } from "./profiles";

export const profileResultsCollection = (profileId) =>
  collection(profilesCollection, profileId, "results").withConverter(converter);

export async function createResult({
  foundCodes,
  elapsedTime,
  rules,
  createdBy,
  createdAt,
}) {
  await addDoc(profileResultsCollection(createdBy), {
    foundCodes,
    elapsedTime,
    rules,
    createdBy,
    createdAt,
  });
}
