import { useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";

import { foundCodesState } from "../../state/game/foundCodes";
import { rulesState } from "../../state/game/rules";
import { GameStatus } from "../../constants/game";
import { statusState } from "../../state/game/status";
import { endedAtState } from "../../state/game/endedAt";

export function useRoomEnded(room, user, player) {
  const isMounted = useRef(false);
  const setStatus = useSetRecoilState(statusState);
  const setEndedAt = useSetRecoilState(endedAtState);
  const setFoundCodes = useSetRecoilState(foundCodesState);
  const setRules = useSetRecoilState(rulesState);

  useEffect(() => {
    setRules(room.rules);
  }, [room.rules, setRules]);

  useEffect(() => {
    setFoundCodes(new Set(player.foundCodes));
  }, [player.foundCodes, setFoundCodes]);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    setStatus(GameStatus.ended);
    setEndedAt(player.endedAt || room.endedAt);
    isMounted.current = true;
  }, [player.endedAt, room.endedAt, setEndedAt, setStatus]);

  return { isMounted, player };
}
