import { atom } from "recoil";

export const newRoomNameState = atom({
  key: "newRoomNameState",
  default: null,
});

export const newRoomIsPrivateState = atom({
  key: "newRoomIsPrivateState",
  default: false,
});
