import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useApp } from "../hooks/shared/useApp";
import { RoomPage } from "../pages/RoomPage";
import { HomePage } from "../pages/HomePage";
import { MainLayout } from "../layouts/MainLayout";
import { AuthLayout } from "../layouts/AuthLayout";
import { ErrorPage } from "../pages/ErrorPage";
import { SinglePlayerPage } from "../pages/SinglePlayerPage";
import { NewRoomPage } from "../pages/NewRoomPage";
import { JoinRoomPage } from "../pages/JoinRoomPage";
import { LeaderboardPage } from "../pages/LeaderboardPage";
import { ProfilePage } from "../pages/ProfilePage";
import { AuthPage } from "../pages/AuthPage";

import "./App.scss";

export function App() {
  const { t } = useTranslation();
  useApp();

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="auth" element={<AuthPage />} />
        <Route path="single" element={<SinglePlayerPage />} />
        <Route path="multi" element={<AuthLayout />}>
          <Route path="rooms">
            <Route index element={<JoinRoomPage />} />
            <Route path="new" element={<NewRoomPage />} />
            <Route path=":id" element={<RoomPage />} />
          </Route>
          <Route path="leaderboard" element={<LeaderboardPage />} />
        </Route>
        <Route path="profiles" element={<AuthLayout />}>
          <Route path=":id" element={<ProfilePage />} />
        </Route>
        <Route
          path="*"
          element={<ErrorPage message={t("app.pageNotFound")} />}
        />
      </Route>
    </Routes>
  );
}
