import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Badge, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { useResultsPerContinent } from "../../../hooks/game/useResultsPerContinent";
import { continentCodes } from "../../../constants/datasets/continents";
import { allCountriesPerCode } from "../../../constants/datasets/countries";
import { isCityModeState } from "../../../state/game/rules";
import { allCodesState } from "../../../state/results/allCodes";
import { themeState } from "../../../state/game/theme";

import "./RoomCompareResults.scss";

export function RoomCompareResults({ players }) {
  const { t } = useTranslation();
  const isCityMode = useRecoilValue(isCityModeState);
  const allCodes = useRecoilValue(allCodesState);
  const theme = useRecoilValue(themeState);
  const resultsPerContinent = useResultsPerContinent(Array.from(allCodes));
  const entries = Array.from(resultsPerContinent.entries());

  const totalPerContinentPerPlayer = useMemo(
    () =>
      new Map(
        players.map((player) => {
          const totals = new Map(
            continentCodes.map((continentCode) => [continentCode, 0])
          );

          player.foundCodes.forEach((foundCode) => {
            const country = allCountriesPerCode.get(foundCode);
            totals.set(country.continent, totals.get(country.continent) + 1);
          });

          return [player.id, totals];
        })
      ),
    [players]
  );

  return (
    <Table
      striped="columns"
      hover
      responsive
      size="sm"
      variant="light"
      className="RoomCompareResults"
    >
      <thead>
        <PlayersRow players={players} />
      </thead>
      <tbody>
        <TotalRow players={players} />
      </tbody>
      {entries.map(([continentCode, results]) => (
        <tbody key={continentCode}>
          <ContinentTotalRow
            continentCode={continentCode}
            players={players}
            totalPerContinentPerPlayer={totalPerContinentPerPlayer}
            results={results}
          />
          {results.map((result) => (
            <tr key={result.iso}>
              <td style={{ width: "35px", textAlign: "right" }}>
                <span className={`fi fi-${result.flag}`} />
              </td>
              <td className="text-nowrap text-start">
                {isCityMode ? result.city.names[0] : result.names[0]}
              </td>
              {players.map((player) => (
                <td
                  key={player.id}
                  style={{
                    color: player.foundCodes.includes(result.iso)
                      ? theme.foundColor
                      : theme.missingColor,
                  }}
                >
                  <FontAwesomeIcon icon={faCircle} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ))}
      <tfoot>
        <tr>
          <th />
          <th className="text-start py-4">{t("multi.rooms.ended.summary")}</th>
          {players.map((player) => (
            <td key={player.id} className="empty"></td>
          ))}
        </tr>
        <TotalRow players={players} spacing={1} />
        {entries.map(([continentCode, results]) => (
          <ContinentTotalRow
            key={continentCode}
            continentCode={continentCode}
            players={players}
            totalPerContinentPerPlayer={totalPerContinentPerPlayer}
            results={results}
            spacing={1}
          />
        ))}
        <PlayersRow players={players} />
      </tfoot>
    </Table>
  );
}

function PlayersRow({ players }) {
  return (
    <tr>
      <th />
      <th />
      {players.map((player) => (
        <th key={player.id} className="vertical py-3">
          {player.username}
        </th>
      ))}
    </tr>
  );
}

function TotalRow({ players, spacing = 4 }) {
  const { t } = useTranslation();
  const allCodes = useRecoilValue(allCodesState);

  return (
    <tr>
      <th />
      <th className={`text-nowrap text-start py-${spacing}`}>
        {t("multi.rooms.ended.total")}
      </th>
      {players.map((player) => (
        <td key={player.id} className={`py-${spacing}`}>
          <Badge bg="primary">
            {player.score}/{allCodes.size}
          </Badge>
        </td>
      ))}
    </tr>
  );
}

function ContinentTotalRow({
  continentCode,
  players,
  totalPerContinentPerPlayer,
  results,
  spacing = 4,
}) {
  const { t } = useTranslation();

  const bgPerPlayer = useMemo(() => {
    let bestIndex = -1;
    let bestValue = -1;

    let worstIndex = -1;
    let worstValue = Infinity;

    players.forEach((player, index) => {
      const value = totalPerContinentPerPlayer
        .get(player.id)
        .get(continentCode);

      if (value > bestValue) {
        bestIndex = index;
        bestValue = value;
      } else if (value <= worstValue) {
        worstIndex = index;
        worstValue = value;
      }
    });

    return new Map(
      players.map((player, index) => {
        const bg =
          index === bestIndex
            ? "success"
            : index === worstIndex
            ? "danger"
            : "dark";
        return [player.id, bg];
      })
    );
  }, [continentCode, players, totalPerContinentPerPlayer]);

  return (
    <tr>
      <th />
      <th className={`text-nowrap text-start py-${spacing}`}>
        {t(`game.results.continents.${continentCode}`)}
      </th>
      {players.map((player, index) => (
        <td key={player.id} className={`py-${spacing}`}>
          <Badge bg={bgPerPlayer.get(player.id)}>
            {totalPerContinentPerPlayer.get(player.id).get(continentCode)}/
            {results.length}
          </Badge>
        </td>
      ))}
    </tr>
  );
}
