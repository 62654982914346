import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

export function ScoreMedal() {
  return (
    <span className="ms-1">
      <FontAwesomeIcon icon={faMedal} className="medal" />
    </span>
  );
}
