import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import reportWebVitals from "./reportWebVitals";
import { App } from "./components/App";
import "./i18n";
import "./firebase";

import "./index.scss";

dayjs.extend(utc);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
