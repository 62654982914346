import { selector } from "recoil";

import { isExtendedModeState, rulesState } from "../../game/rules";
import { statusState } from "../../game/status";
import { foundCodesState } from "../../game/foundCodes";
import { DifficultyRule } from "../../../constants/rules";
import { GameStatus } from "../../../constants/game";
import {
  allCountriesPerCode,
  extendedCodes,
} from "../../../constants/datasets/countries";

export const visibleCountriesState = selector({
  key: "visibleCountriesState",
  get: ({ get }) => {
    const rules = get(rulesState);
    const gameStatus = get(statusState);
    const foundCodes = get(foundCodesState);
    const isExtendedMode = get(isExtendedModeState);

    const isDifficultyHard =
      rules.difficulty === DifficultyRule.hiddenTerritories;
    const hasGameEnded = gameStatus === GameStatus.ended;
    const shouldHideRemaining = isDifficultyHard && !hasGameEnded;

    const codes = [];
    extendedCodes.forEach((code) => {
      const parent = allCountriesPerCode.get(code).parent;
      const isParentFound = !isExtendedMode && parent && foundCodes.has(parent);

      if (!shouldHideRemaining || foundCodes.has(code) || isParentFound) {
        codes.push(code);
      }
    });

    return codes;
  },
});
