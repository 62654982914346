import { selector } from "recoil";
import { SimpleMarkerSymbol } from "@arcgis/core/symbols";
import Color from "@arcgis/core/Color";
import UniqueValueInfo from "@arcgis/core/renderers/support/UniqueValueInfo";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SizeVariable from "@arcgis/core/renderers/visualVariables/SizeVariable";
import SizeStop from "@arcgis/core/renderers/visualVariables/support/SizeStop";

import { themeState } from "../../game/theme";
import { CITY_POPULATION_FIELD, CODE_FIELD } from "../../../constants/globe";
import { isCityModeState } from "../../game/rules";
import { visibleCitiesState } from "./visible";
import { colorsState } from "../../results/colors";

export const citiesRendererState = selector({
  key: "citiesRendererState",
  get: ({ get }) => {
    const theme = get(themeState);
    const isCityMode = get(isCityModeState);
    const colors = get(colorsState);
    const visibleCities = get(visibleCitiesState);

    if (!isCityMode) {
      return null;
    }

    const createInfo = (value) => {
      const color = Color.fromHex(colors.get(value));
      const outlineColor = Color.fromHex(theme.outlineColor);

      return new UniqueValueInfo({
        value,
        symbol: new SimpleMarkerSymbol({
          style: "circle",
          color,
          outline: { color: outlineColor, width: 1 },
        }),
      });
    };

    return new UniqueValueRenderer({
      field: CODE_FIELD,
      uniqueValueInfos: visibleCities.map(createInfo),
      visualVariables: new SizeVariable({
        field: CITY_POPULATION_FIELD,
        stops: [
          new SizeStop({ size: 7, value: 10e3 - 1 }),
          new SizeStop({ size: 8, value: 100e3 - 1 }),
          new SizeStop({ size: 9, value: 500e3 - 1 }),
          new SizeStop({ size: 10, value: 1e6 - 1 }),
          new SizeStop({ size: 11, value: 5e6 - 1 }),
          new SizeStop({ size: 12, value: 10e6 - 1 }),
        ],
      }),
    });
  },
});
