import { useTranslation } from "react-i18next";

import { CreateRoom } from "../components/room/notStarted/CreateRoom";
import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";

export function NewRoomPage() {
  const { t } = useTranslation();
  useDocumentTitle(t("app.createRoom"));

  return <CreateRoom />;
}
