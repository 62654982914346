import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import { Badge } from "react-bootstrap";

import { foundCodesState } from "../../../state/game/foundCodes";

export function ContinentCounter({ results, totalResults }) {
  const foundCodes = useRecoilValue(foundCodesState);
  const totalFound = useMemo(
    () =>
      results.reduce(
        (total, country) => (foundCodes.has(country.iso) ? total + 1 : total),
        0
      ),
    [results, foundCodes]
  );

  return (
    <Badge bg="light" text="dark">
      {totalFound}/{totalResults}
    </Badge>
  );
}
