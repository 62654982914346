import { useTranslation } from "react-i18next";

import { useForm } from "../../shared/useForm";
import { authFormState } from "../../../state/forms/auth";
import { resetPassword } from "../../../collections/users";
import { useAuthSuccess } from "./useAuthSuccess";

export function useResetPasswordForm() {
  const { t } = useTranslation();

  const onSuccess = useAuthSuccess({
    title: t("notifications.resetPassword.title"),
    message: t("notifications.resetPassword.message"),
    autoHideAfterSeconds: 60,
  });

  return useForm(authFormState, resetPassword, onSuccess, false);
}
