import {
  faCircle,
  faGem,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import { allCountriesPerCode } from "../../constants/datasets/countries";

import "./Username.scss";

export function Username({
  id,
  value,
  isOnline,
  isActive,
  isStaff,
  isVip,
  country,
  tooltipPlacement = "right",
  clickable = true,
  icon = true,
  flag = true,
  maxWidth = true,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const status = isActive ? "online" : isOnline ? "away" : "offline";
  const flagCode = flag ? allCountriesPerCode.get(country)?.flag || "xx" : null;
  const labels = [t(`multi.status.${status}`)];

  if (isStaff) {
    labels.push("Staff");
  } else if (isVip) {
    labels.push("VIP");
  }

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        <Tooltip id={`username-tooltip-${id}`}>{labels.join(", ")}</Tooltip>
      }
    >
      <span className="Username d-inline-flex align-items-center">
        <FontAwesomeIcon icon={faCircle} className={`status ${status} me-2`} />
        {flag && <span className={`fi fi-${flagCode} me-1`} />}
        {clickable ? (
          <Button
            variant="link"
            onClick={() =>
              navigate(`/profiles/${id}`, {
                state: { origin: location.pathname },
              })
            }
            className={clsx("p-0", maxWidth && "max-width")}
          >
            {value}
          </Button>
        ) : (
          <span className={clsx("p-0", maxWidth && "max-width")}>{value}</span>
        )}
        {icon && isStaff ? (
          <FontAwesomeIcon icon={faUserShield} className="ms-1" />
        ) : isVip ? (
          <FontAwesomeIcon icon={faGem} className="ms-1" />
        ) : null}
      </span>
    </OverlayTrigger>
  );
}
