import { useTranslation } from "react-i18next";
import { useBeforeUnload } from "react-use";

import { useIsMulti } from "../shared/useIsMulti";

export function useGameStarted() {
  const { t } = useTranslation();
  const isMulti = useIsMulti();
  useBeforeUnload(!isMulti, t("app.confirmationMessage"));
}
