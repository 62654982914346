import { useTranslation } from "react-i18next";

import { Game } from "../components/game/Game";
import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";

export function SinglePlayerPage() {
  const { t } = useTranslation();
  useDocumentTitle(t("app.singlePlayer"));

  return <Game />;
}
