import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useSignUpForm } from "../../hooks/forms/auth/useSignUpForm";
import { CountryInput } from "../../inputs/CountryInput";

export function SignUpForm() {
  const { t } = useTranslation();
  const state = useSignUpForm();

  return (
    <Form onSubmit={state.onSubmit} validated={state.isValidated}>
      {!!state.error && (
        <Alert variant="danger">{state.error.toString()}</Alert>
      )}
      <Form.Group controlId="username" className="mb-3">
        <Form.Label>{t("forms.auth.username.label")}</Form.Label>
        <Form.Control
          required
          autoFocus
          autoComplete="off"
          minLength={3}
          maxLength={25}
          value={state.values.username}
          placeholder="Bob"
          onChange={state.onInputChange("username")}
        />
        <Form.Text muted>{t("forms.auth.username.help")}</Form.Text>
      </Form.Group>
      <CountryInput
        value={state.values.country}
        onChange={state.onSelectChange("country")}
      />
      <Form.Check
        id="is-anon"
        type="switch"
        className="mb-3"
        label={t("forms.auth.isAnon.label")}
        checked={state.values.isAnon}
        onChange={state.onSwitchChange("isAnon")}
      />
      {state.values.isAnon ? (
        <Alert variant="warning">{t("forms.auth.isAnon.warning")}</Alert>
      ) : (
        <>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>{t("forms.auth.email.label")}</Form.Label>
            <Form.Control
              required
              type="email"
              value={state.values.email}
              placeholder="bob@gmail.com"
              onChange={state.onInputChange("email")}
            />
          </Form.Group>
          <Form.Group controlId="password" className="mb-3">
            <Form.Label>{t("forms.auth.password.label")}</Form.Label>
            <Form.Control
              required
              minLength={8}
              type="password"
              value={state.values.password}
              placeholder="************"
              onChange={state.onInputChange("password")}
            />
            <Form.Text muted>{t("forms.auth.password.help")}</Form.Text>
          </Form.Group>
        </>
      )}
      <Button variant="primary" type="submit" disabled={state.isLoading}>
        {state.isLoading ? t("app.isLoading") : t("auth.signUp")}
      </Button>
    </Form>
  );
}
