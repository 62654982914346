import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export function Help() {
  const onClick = () => window.Tawk_API.toggle();

  return (
    <Button variant="light" tabIndex={0} onClick={onClick}>
      <FontAwesomeIcon icon={faQuestion} fixedWidth />
    </Button>
  );
}
