import { Card, Modal, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useAuthModal } from "../../hooks/auth/useAuthModal";
import { AuthModalTab } from "./AuthModalTab";

export function AuthModal() {
  const { t } = useTranslation();
  const { tabs, tab, setTab, onHide } = useAuthModal();

  return (
    <Modal show onHide={onHide} backdrop="static" fullscreen="sm-down">
      <Modal.Header closeButton>
        <Modal.Title>{t("pages.auth.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Card>
          <Card.Header>
            <Nav variant="tabs" activeKey={tab} onSelect={setTab}>
              {tabs.map((key) => (
                <Nav.Item key={key}>
                  <Nav.Link eventKey={key}>{t(`auth.${key}`)}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            <AuthModalTab value={tab} />
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
