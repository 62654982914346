import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DifficultyRule, ModeRule } from "../constants/rules";
import { useRulesForm } from "../hooks/forms/useRulesForm";

export function RulesForm({ autoFocus = false, disabled = false }) {
  const { t } = useTranslation();
  const {
    rules,
    totalMainCodes,
    totalExtendedCodes,
    onSelectChange,
    onSwitchChange,
  } = useRulesForm();

  return (
    <Form>
      <Form.Group controlId="mode" className="mb-3">
        <Form.Label>{t("forms.rules.mode.label")}</Form.Label>
        <Form.Select
          autoFocus={autoFocus}
          disabled={disabled}
          value={rules.mode}
          onChange={onSelectChange("mode")}
        >
          <option value={ModeRule.mainCountries}>
            {t("forms.rules.mode.options.mainCountries", {
              count: totalMainCodes,
            })}
          </option>
          <option value={ModeRule.extendedTerritories}>
            {t("forms.rules.mode.options.extendedTerritories", {
              count: totalExtendedCodes,
            })}
          </option>
          <option value={ModeRule.capitalCities}>
            {t("forms.rules.mode.options.capitalCities", {
              count: totalMainCodes,
            })}
          </option>
          <option value={ModeRule.extendedCities}>
            {t("forms.rules.mode.options.extendedCities", {
              count: totalExtendedCodes,
            })}
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="difficulty" className="mb-3">
        <Form.Label>{t("forms.rules.difficulty.label")}</Form.Label>
        <Form.Select
          disabled={disabled}
          value={rules.difficulty}
          onChange={onSelectChange("difficulty")}
        >
          <option value={DifficultyRule.visibleBorders}>
            {t("forms.rules.difficulty.options.visibleBorders")}
          </option>
          <option value={DifficultyRule.hiddenBorders}>
            {t("forms.rules.difficulty.options.hiddenBorders")}
          </option>
          <option value={DifficultyRule.hiddenTerritories}>
            {t("forms.rules.difficulty.options.hiddenTerritories")}
          </option>
        </Form.Select>
        <Form.Text muted>
          {t(`forms.rules.difficulty.help.${rules.difficulty}`)}
        </Form.Text>
      </Form.Group>
      <Form.Check
        id="timer"
        type="switch"
        label={t("forms.rules.timer.label")}
        disabled={disabled}
        checked={rules.timer}
        onChange={onSwitchChange("timer")}
      />
      <Form.Check
        id="allowTypos"
        type="switch"
        label={t("forms.rules.allowTypos.label")}
        disabled={disabled}
        checked={rules.allowTypos}
        onChange={onSwitchChange("allowTypos")}
      />
      <Form.Check
        id="allowInformalNames"
        type="switch"
        label={t("forms.rules.allowInformalNames.label")}
        disabled={disabled}
        checked={rules.allowInformalNames}
        onChange={onSwitchChange("allowInformalNames")}
      />
      <Form.Check
        id="allowAbbreviations"
        type="switch"
        label={t("forms.rules.allowAbbreviations.label")}
        disabled={disabled}
        checked={rules.allowAbbreviations}
        onChange={onSwitchChange("allowAbbreviations")}
      />
    </Form>
  );
}
