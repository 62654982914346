import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { AuthModal } from "../components/auth/AuthModal";
import { Globe } from "../components/shared/Globe";
import { LoadingPage } from "../pages/LoadingPage";
import { Notifications } from "../components/notifications/Notifications";
import { Toolbar } from "../components/toolbar/Toolbar";
import { areLayersReadyState } from "../state/layers/ready";
import { shouldRequestAuthState } from "../state/users/auth";
import { ErrorPage } from "../pages/ErrorPage";
import { useHasWebGL } from "../hooks/globe/useHasWebGL";

export function MainLayout() {
  const hasWebgl = useHasWebGL();
  const areLayersReady = useRecoilValue(areLayersReadyState);
  const shouldRequestAuth = useRecoilValue(shouldRequestAuthState);

  return (
    <>
      <Globe />
      <div className="AppContentTopLeftToolbar">
        <Toolbar />
      </div>
      <div className="AppContentBottom">
        <Notifications />
      </div>
      {!hasWebgl ? (
        <ErrorPage hasWebgl={false} />
      ) : areLayersReady ? (
        <Outlet />
      ) : (
        <LoadingPage />
      )}
      {shouldRequestAuth.value && <AuthModal />}
    </>
  );
}
