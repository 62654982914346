import { AuthTab } from "../../constants/auth";
import { LogInForm } from "../../forms/auth/LogInForm";
import { SignUpForm } from "../../forms/auth/SignUpForm";
import { UpgradeAccountForm } from "../../forms/auth/UpgradeAccountForm";
import { ResetPasswordForm } from "../../forms/auth/ResetPasswordForm";

export function AuthModalTab({ value }) {
  switch (value) {
    case AuthTab.signUp:
      return <SignUpForm />;
    case AuthTab.logIn:
      return <LogInForm />;
    case AuthTab.resetPassword:
      return <ResetPasswordForm />;
    case AuthTab.upgradeAccount:
      return <UpgradeAccountForm />;
    default:
      return null;
  }
}
