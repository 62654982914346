import { ProfileTab } from "../../constants/profiles";
import { ModeRule } from "../../constants/rules";
import { AchievementsTab } from "./AchievementsTab";
import { StatisticsTab } from "./StatisticsTab";
import { EditTab } from "./EditTab";

export function ProfileActiveTab({ activeKey, profile, userInfo }) {
  switch (activeKey) {
    case ProfileTab.statistics:
      return <StatisticsTab profile={profile} />;
    case ProfileTab.achievementsMainCountries:
      return (
        <AchievementsTab profile={profile} mode={ModeRule.mainCountries} />
      );
    case ProfileTab.achievementsExtendedTerritories:
      return (
        <AchievementsTab
          profile={profile}
          mode={ModeRule.extendedTerritories}
        />
      );
    case ProfileTab.achievementsCapitalCities:
      return (
        <AchievementsTab profile={profile} mode={ModeRule.capitalCities} />
      );
    case ProfileTab.achievementsExtendedCities:
      return (
        <AchievementsTab profile={profile} mode={ModeRule.extendedCities} />
      );
    case ProfileTab.edit:
      return <EditTab profile={profile} userInfo={userInfo} />;
    default:
      return null;
  }
}
