import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useThemeForm } from "../hooks/forms/useThemeForm";
import { ThemeName } from "../constants/themes";

export function ThemeForm() {
  const { t } = useTranslation();
  const { themeName, onChange } = useThemeForm();

  return (
    <Form>
      <Form.Group controlId="theme" className="mb-3">
        <Form.Label>{t("forms.theme.label")}</Form.Label>
        <Form.Select tabIndex={0} autoFocus value={themeName} onChange={onChange}>
          {Object.values(ThemeName).map((themeName) => (
            <option key={themeName} value={themeName}>
              {t(`forms.theme.options.${themeName}`)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Form>
  );
}
