import { useCallback, useEffect, useRef, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { limit, orderBy, query } from "firebase/firestore";
import useSound from "use-sound";
import { useRecoilState } from "recoil";

import { sleep } from "../../helpers/sleep";
import {
  createRoomMessage,
  roomMessagesCollection,
} from "../../collections/messages";
import notificationSound from "../../sounds/new-message.mp3";
import { isChatMutedState, isChatOpenState } from "../../state/rooms/chat";

export function useRoomChat(room, user) {
  const isMounted = useRef(false);
  const inputRef = useRef();
  const messagesEndRef = useRef();
  const [previousLength, setPreviousLength] = useState(0);
  const [newMessagesCounter, setNewMessagesCounter] = useState(0);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useRecoilState(isChatOpenState);
  const [isMuted, setIsMuted] = useRecoilState(isChatMutedState);
  const [isSending, setIsSending] = useState(false);
  const [value, setValue] = useState("");
  const [messages, areMessagesLoading, loadMessagesError] = useCollectionData(
    query(
      roomMessagesCollection(room.id),
      orderBy("createdAt", "desc"),
      limit(250)
    )
  );
  const [playNotification] = useSound(notificationSound);

  messages?.sort((a, b) => a.createdAt - b.createdAt);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onChange = (event) => setValue(event.target.value);
  const onToggle = () => {
    setIsOpen((isOpen) => {
      if (isOpen) {
        setNewMessagesCounter(0);
        setPreviousLength(messages?.length);
      }

      return !isOpen;
    });
  };
  const onMute = (event) => {
    switch (event.type) {
      case "click":
        event.stopPropagation();
        setIsMuted((isMuted) => !isMuted);
        break;
      case "keydown":
        if (event.code === "Space" || event.code === "Enter") {
          event.preventDefault();
          setIsMuted((isMuted) => !isMuted);
        }
        break;
      default:
        break;
    }
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!value) {
        return;
      }

      try {
        const content = value;
        setIsSending(true);
        setError(null);
        setValue("");

        await createRoomMessage({
          roomId: room.id,
          playerId: user.uid,
          username: user.displayName,
          content,
        });
      } catch (error) {
        setError(error);
      } finally {
        setIsSending(false);
      }
    },
    [room.id, user.displayName, user.uid, value]
  );

  const onSelect = useCallback(async () => {
    setNewMessagesCounter(0);
    await sleep(1);
    inputRef.current?.focus();
    await sleep(1);
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    if (!messages?.length) {
      return;
    }

    if (isOpen) {
      onSelect().catch(console.error);
    } else if (isMounted.current) {
      if (messages.length !== previousLength) {
        const newCounter = messages.length - previousLength;
        setPreviousLength(messages.length);
        setNewMessagesCounter((counter) => counter + 1);

        if (newCounter && !isMuted) {
          playNotification();
        }
      }
    }
  }, [
    messages?.length,
    isOpen,
    onSelect,
    playNotification,
    previousLength,
    isMuted,
  ]);

  useEffect(() => {
    if (!isMounted.current && !areMessagesLoading) {
      isMounted.current = true;
    }
  }, [areMessagesLoading]);

  return {
    error: error || loadMessagesError,
    areMessagesLoading,
    isSending,
    isOpen,
    isMuted,
    inputRef,
    messagesEndRef,
    value,
    messages,
    newMessagesCounter,
    onToggle,
    onMute,
    onSelect,
    onChange,
    onSubmit,
  };
}
