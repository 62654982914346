import { useEffect, useRef } from "react";

import { useOnReset } from "../game/useOnReset";

export function useHome() {
  const isMounted = useRef(false);
  const onReset = useOnReset();

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    isMounted.current = true;
    onReset();
  }, [onReset]);
}
