import { Alert, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RoomLobbyPlayers } from "./RoomLobbyPlayers";
import { RulesForm } from "../../../forms/RulesForm";
import { useRoomLobby } from "../../../hooks/rooms/useRoomLobby";
import { RoomLobbyAlert } from "./RoomLobbyAlert";
import { Spinner } from "../../shared/Spinner";

export function RoomLobby({ room, user, playersValues }) {
  const { t } = useTranslation();
  const values = useRoomLobby(room, user, playersValues);

  return (
    <Modal
      show
      onHide={values.onLeave}
      backdrop="static"
      size="lg"
      fullscreen="lg-down"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("multi.rooms.lobby.title", { name: room.name })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!values.error && (
          <Alert variant="danger">{values.error.toString()}</Alert>
        )}
        <Row>
          <Col lg={6}>
            {playersValues.arePlayersLoading ? (
              <Spinner className="mb-3" />
            ) : (
              <>
                <RoomLobbyPlayers room={room} players={playersValues.players} />
                <RoomLobbyAlert
                  admin={values.admin}
                  arePlayersReady={values.arePlayersReady}
                />
              </>
            )}
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Header>{t("app.rules")}</Card.Header>
              <Card.Body>
                <RulesForm disabled={room.createdBy !== user.uid} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="primary"
          size="lg"
          className="me-auto"
          disabled={values.startDisabled}
          onClick={values.onStart}
        >
          {t("multi.rooms.lobby.buttons.start")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
