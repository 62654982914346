import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import { useForm } from "../shared/useForm";
import { editProfileState } from "../../state/forms/editProfile";
import { editProfile } from "../../collections/users";
import { useNotifications } from "../notifications/useNotifications";

export function useEditProfileForm(defaultValues) {
  const { t } = useTranslation();
  const setValues = useSetRecoilState(editProfileState);
  const [isMounted, setIsMounted] = useState(false);
  const { notify } = useNotifications();

  useEffect(() => {
    if (isMounted) {
      return;
    }

    setValues(defaultValues);
    setIsMounted(true);
  }, [defaultValues, isMounted, setValues]);

  const onSuccess = useCallback(() => {
    notify({
      title: t("notifications.profileUpdated.title"),
      message: t("notifications.profileUpdated.message"),
      autoHideAfterSeconds: 10,
    });
  }, [notify, t]);

  return useForm(editProfileState, editProfile, onSuccess, false);
}
