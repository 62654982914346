import {
  addDoc,
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";

import { converter } from "../helpers/collections";
import { profilesCollection } from "./profiles";

export const profileAchievementsCollection = (profileId) =>
  collection(profilesCollection, profileId, "achievements").withConverter(
    converter
  );

export async function createAchievement({ name, createdBy, createdAt }) {
  await addDoc(profileAchievementsCollection(createdBy), {
    name,
    createdBy,
    createdAt,
  });
}

export async function hasAchievement(profileId, name) {
  if (!profileId) {
    return false;
  }

  const snapshot = await getCountFromServer(
    query(profileAchievementsCollection(profileId), where("name", "==", name))
  );

  return snapshot.data().count > 0;
}
