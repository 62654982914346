import { selector } from "recoil";

import { isCityModeState } from "../game/rules";
import { allNamesState } from "./allNames";

export const resultNamesState = selector({
  key: "resultNamesState",
  get: ({ get }) => {
    const isCityMode = get(isCityModeState);
    const allNames = get(allNamesState);

    return isCityMode ? allNames.cities : allNames.countries;
  },
});
