import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import clsx from "clsx";

import { useGameInput } from "../../../hooks/game/useGameInput";
import { rulesState } from "../../../state/game/rules";

import "./GameInput.scss";

export function GameInput() {
  const { t } = useTranslation();
  const rules = useRecoilValue(rulesState);
  const state = useGameInput();

  return (
    <div className={clsx("GameInput", state.isShaking && "shaking")}>
      <InputGroup size="lg">
        <Form.Control
          autoFocus
          value={state.value}
          placeholder={t(`game.input.placeholder.${rules.mode}`)}
          type="text"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          autoSave="off"
          spellCheck={false}
          data-lpignore="true"
          data-form-type="other"
          onChange={state.onChange}
          onPaste={state.onForbidden}
          onDrop={state.onForbidden}
          onKeyDown={state.onKeyDown}
        />
        {state.suggestion && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-suggestion">Suggestion</Tooltip>}
          >
            <Button variant="success" onClick={state.onSuggestionClick}>
              {state.suggestion}
            </Button>
          </OverlayTrigger>
        )}
      </InputGroup>
    </div>
  );
}
