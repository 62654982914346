import { useCallback, useMemo, useRef, useState } from "react";
import { orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { roomsCollection } from "../../collections/rooms";
import { rulesState } from "../../state/game/rules";
import { normalizeString } from "../../helpers/string";

export function useJoinRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const minDate = useRef(dayjs.utc().subtract(1, "day").valueOf());
  const [filtersOn, setFiltersOn] = useState(false);
  const [search, setSearch] = useState("");
  const rules = useRecoilValue(rulesState);
  const [filters, setFilters] = useState(rules);
  const setRules = useSetRecoilState(rulesState);

  const constraints = [
    where("createdAt", ">=", minDate.current),
    where("isPrivate", "==", false),
    where("startedAt", "==", null),
  ];
  if (filtersOn) {
    constraints.push(where("rules", "==", filters));
  }

  const [rooms, isLoading, error] = useCollectionData(
    query(roomsCollection, ...constraints, orderBy("createdAt", "desc"))
  );

  const filteredRooms = useMemo(
    () =>
      rooms && search
        ? rooms.filter((room) =>
            normalizeString(room.name).includes(normalizeString(search))
          )
        : rooms,
    [rooms, search]
  );

  const onSearchChange = useCallback(
    (event) => setSearch(event.target.value),
    []
  );

  const onFiltersOnChange = useCallback(
    () => setFiltersOn((filtersOn) => !filtersOn),
    []
  );

  const onSelectChange = useCallback(
    (ruleName) => (event) => {
      setFilters((rules) => ({ ...rules, [ruleName]: event.target.value }));
    },
    []
  );

  const onSwitchChange = useCallback(
    (ruleName) => (event) => {
      setFilters((rules) => ({ ...rules, [ruleName]: event.target.checked }));
    },
    []
  );

  const onHide = useCallback(() => {
    setRules(filters);

    const origin = location.state?.origin || "/";
    navigate(origin);
  }, [filters, location.state?.origin, navigate, setRules]);

  return {
    filtersOn,
    isLoading,
    error,
    filters,
    rooms: filteredRooms,
    onFiltersOnChange,
    onSelectChange,
    onSwitchChange,
    onSearchChange,
    onHide,
  };
}
