import TimeAgo from "react-timeago";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import fr from "react-timeago/lib/language-strings/fr";
import en from "react-timeago/lib/language-strings/en";
import es from "react-timeago/lib/language-strings/es";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import { Language } from "../../constants/language";

export function FormattedTime({ value }) {
  const { i18n } = useTranslation();

  const formatter = useMemo(() => {
    switch (i18n.language) {
      case Language.fr:
        return buildFormatter(fr);
      case Language.es:
        return buildFormatter(es);
      default:
        return buildFormatter(en);
    }
  }, [i18n.language]);

  return <TimeAgo date={value} formatter={formatter} />;
}
