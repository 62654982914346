import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../firebase";

export function ProfileUpgradeWarning({ profile, className = null }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);

  if (profile.isUpgraded) {
    return null;
  }

  return (
    <Alert className={className} variant="warning">
      <Alert.Heading>{t("app.warning")}</Alert.Heading>
      {profile.id === user?.uid
        ? t("pages.profile.youShouldUpgradeWarning")
        : t("pages.profile.theyShouldUpgradeWarning", {
            username: profile.username,
          })}
      {profile.id === user?.uid && (
        <div>
          <hr />
          <Button
            size="lg"
            variant="warning"
            onClick={() =>
              navigate("/auth", { state: { origin: location.pathname } })
            }
          >
            {t("auth.upgradeAccount")}
          </Button>
        </div>
      )}
    </Alert>
  );
}
