import { Alert, Row } from "react-bootstrap";
import clsx from "clsx";

import { useProfileCompletionAchievements } from "../../hooks/profiles/useProfileCompletionAchievements";
import { Spinner } from "../shared/Spinner";

export function AchievementsCompletionBadges({ mode, allowTypos }) {
  const state = useProfileCompletionAchievements(mode, allowTypos);

  if (state.error) {
    return <Alert variant="danger">{state.error.toString()}</Alert>;
  }

  if (state.isLoading) {
    return <Spinner />;
  }

  const unlocked = (difficulty) =>
    state.foundNames.has(state.getName(difficulty));

  return (
    <Row className="justify-content-center">
      {state.difficulties.map((difficulty) => (
        <img
          alt={
            unlocked(difficulty) ? `Achievement unlocked` : "Achievement locked"
          }
          key={difficulty}
          src={`/badges/${state.getName(difficulty)}.png`}
          className={clsx("mb-2", unlocked(difficulty) && "unlocked")}
        />
      ))}
    </Row>
  );
}
