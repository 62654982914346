import { Alert, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RulesFilterForm } from "../../forms/RulesFilterForm";
import { useLeaderboard } from "../../hooks/leaderboard/useLeaderboard";
import { LeaderboardDropdown } from "./LeaderboardDropdown";
import { ListScores } from "./ListScores";

export function Leaderboard({ defaultFilters = null, onClose = null }) {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    filters,
    leaderboardId,
    scores,
    pagination,
    onSelectChange,
    onSwitchChange,
    onLeaderboardIdChange,
    onHide,
  } = useLeaderboard(defaultFilters, onClose);

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      size="xl"
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("app.leaderboard")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>{t("app.filters")}</Card.Header>
          <Card.Body>
            <RulesFilterForm
              value={filters}
              onSelectChange={onSelectChange}
              onSwitchChange={onSwitchChange}
            />
          </Card.Body>
        </Card>
        <div className="text-center mt-3">
          <LeaderboardDropdown
            className="d-inline w-50"
            value={leaderboardId}
            onChange={onLeaderboardIdChange}
          />
        </div>
        {!!error && (
          <Alert className="mt-3" variant="danger">
            {error.toString()}
          </Alert>
        )}
        <ListScores
          isLoading={isLoading}
          scores={scores}
          pagination={pagination}
        />
      </Modal.Body>
    </Modal>
  );
}
