import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAeistSWwCnH9o42IlCRdESG_FPwVM5D7I",
  authDomain: "geoquiz2-ac1a2.firebaseapp.com",
  databaseURL: "https://geoquiz2-ac1a2-default-rtdb.firebaseio.com/",
  projectId: "geoquiz2-ac1a2",
  storageBucket: "geoquiz2-ac1a2.appspot.com",
  messagingSenderId: "195034760078",
  appId: "1:195034760078:web:012f0aa11ad0db98a21adf",
  measurementId: "G-HBTG69Q2G3",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);

export const analytics = getAnalytics(app);

auth.useDeviceLanguage();
