import { useCallback } from "react";
import { signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { useNotifications } from "../notifications/useNotifications";
import { auth } from "../../firebase";

export function useLogOut() {
  const { t } = useTranslation();
  const { notify } = useNotifications();

  return useCallback(async () => {
    try {
      await signOut(auth);
      notify({
        title: t("notifications.loggedOut.title"),
        message: t("notifications.loggedOut.message"),
        autoHideAfterSeconds: 10,
      });
    } catch (error) {
      console.error(error);
    }
  }, [notify, t]);
}
