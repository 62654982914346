import { useRecoilValue } from "recoil";

import { foundCodesState } from "../../state/game/foundCodes";
import { allCodesState } from "../../state/results/allCodes";

export function useGameProgress() {
  const foundCodes = useRecoilValue(foundCodesState);
  const allCodes = useRecoilValue(allCodesState);

  const count = foundCodes.size;
  const total = allCodes.size;

  const progress = (count * 100) / total;
  const percentage = progress.toFixed(1);

  return {
    progress,
    percentage,
    count,
    total,
  };
}
