import { Button, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useListRooms } from "../../../hooks/rooms/useListRooms";
import { ListRoomRow } from "./ListRoomRow";

export function ListRooms({ filters, isLoading, rooms }) {
  const { t } = useTranslation();
  const { onCreate } = useListRooms(filters);

  return (
    <Table striped bordered hover className="align-middle mt-3">
      <thead>
        <tr>
          <th>{t("app.roomName")}</th>
          <th>{t("multi.rooms.join.createdBy")}</th>
          <th>{t("multi.rooms.join.createdAt")}</th>
          <th>{t("multi.rooms.join.join")}</th>
        </tr>
      </thead>
      <tbody>
        {isLoading || !rooms ? (
          <tr>
            <td colSpan={4} className="text-center">
              <Spinner />
            </td>
          </tr>
        ) : rooms.length ? (
          rooms.map((room) => <ListRoomRow key={room.id} room={room} />)
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              {t(`multi.rooms.join.empty`)}{" "}
              <Button
                tabIndex={0}
                variant="primary"
                size="sm"
                onClick={onCreate}
              >
                {t(`app.createRoom`)}
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
