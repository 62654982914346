import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { startedAtState } from "../../state/game/startedAt";
import { useStartGame } from "../game/useStartGame";

export function useRoomStartedOrEnded(room, user, players) {
  const startedAt = useRecoilValue(startedAtState);
  const onStart = useStartGame();
  const now = Date.now();

  players?.sort((a, b) => {
    const endedAtA = a.endedAt || room.endedAt || now;
    const endedAtB = b.endedAt || room.endedAt || now;

    return b.score === a.score
      ? endedAtA === endedAtB
        ? a.updatedAt === b.updatedAt
          ? a.username.toLowerCase() === b.username.toLowerCase()
            ? a.createdAt - b.createdAt
            : a.username.localeCompare(b.username)
          : a.updatedAt - b.updatedAt
        : endedAtA - endedAtB
      : b.score - a.score;
  });

  useEffect(() => {
    if (room.startedAt && startedAt !== room.startedAt) {
      onStart(room.startedAt);
    }
  }, [onStart, room.startedAt, startedAt]);
}
