export const AuthTab = {
  signUp: "signUp",
  logIn: "logIn",
  resetPassword: "resetPassword",
  upgradeAccount: "upgradeAccount",
};

export const AuthNextPage = {
  myProfile: "myProfile",
};
