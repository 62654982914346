import { Col, Row, Table } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { useResultsPerContinent } from "../../../hooks/game/useResultsPerContinent";
import { totalResultsPerContinentState } from "../../../state/results/totalPerContinent";
import { colorsState } from "../../../state/results/colors";
import { ResultsCard } from "./ResultsCard";
import { ContinentCounter } from "./ContinentCounter";
import { isCityModeState } from "../../../state/game/rules";
import { Title } from "../../shared/Title";
import { allResultCodesState } from "../../../state/results/allResultCodes";

export function SharableResults({ innerRef }) {
  const allResultCodes = useRecoilValue(allResultCodesState);
  const resultsPerContinent = useResultsPerContinent(allResultCodes);
  const totalResultsPerContinent = useRecoilValue(
    totalResultsPerContinentState
  );

  const getProps = (continentCode) => ({
    continentCode,
    results: resultsPerContinent.get(continentCode),
    totalResults: totalResultsPerContinent.get(continentCode),
  });

  return (
    <div ref={innerRef} style={{ width: "1660px", padding: "1rem" }}>
      <Row>
        <Col className="m-auto">
          <SharableContinentCountries {...getProps("NA")} />
          <SharableContinentCountries {...getProps("SA")} />
        </Col>
        <Col className="m-auto">
          <SharableContinentCountries {...getProps("EU")} />
        </Col>
        <Col className="m-auto">
          <SharableContinentCountries {...getProps("AF")} />
        </Col>
        <Col className="m-auto">
          <SharableContinentCountries {...getProps("AS")} />
        </Col>
        <Col className="m-auto">
          <Title />
          <div className="my-4">
            <ResultsCard />
          </div>
          <SharableContinentCountries {...getProps("OC")} />
          {!!totalResultsPerContinent.get("AN") && (
            <SharableContinentCountries {...getProps("AN")} />
          )}
        </Col>
      </Row>
    </div>
  );
}

function SharableContinentCountries({ continentCode, results, totalResults }) {
  const { t } = useTranslation();
  const colors = useRecoilValue(colorsState);
  const isCityMode = useRecoilValue(isCityModeState);

  return (
    <Table striped size="sm" variant="dark">
      <thead>
        <tr>
          <th colSpan={2} className="px-2">
            <span className="float-end">
              <ContinentCounter results={results} totalResults={totalResults} />
            </span>
            {t(`game.results.continents.${continentCode}`)}
          </th>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <tr key={result.iso}>
            <td className="text-end align-middle" style={{ width: "35px" }}>
              <span className={`fi fi-${result.flag}`} />
            </td>
            <td style={{ color: colors.get(result.iso) }}>
              {isCityMode ? result.city.names[0] : result.names[0]}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
