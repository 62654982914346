import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useSettingsForm } from "../hooks/forms/useSettingsForm";
import { Quality } from "../constants/quality";

export function SettingsForm() {
  const { t } = useTranslation();
  const { quality, autoZoom, onQualityChange, onAutoZoomChange } =
    useSettingsForm();

  return (
    <Form>
      <Form.Group controlId="theme" className="mb-3">
        <Form.Label>{t("forms.settings.quality.label")}</Form.Label>
        <Form.Select
          tabIndex={0}
          autoFocus
          value={quality}
          onChange={onQualityChange}
        >
          {Object.keys(Quality).map((key) => (
            <option key={key} value={Quality[key]}>
              {t(`forms.settings.quality.options.${key}`)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="theme" className="mb-3">
        <Form.Check
          tabIndex={0}
          id="auto-zoom"
          type="switch"
          label={t("forms.settings.autoZoom.label")}
          checked={autoZoom}
          onChange={onAutoZoomChange}
        />
      </Form.Group>
    </Form>
  );
}
