import { useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RoomCompareResults } from "./RoomCompareResults";

export function RoomEndedActions({ players }) {
  const { t } = useTranslation();
  const [isCompareOpen, setCompareOpen] = useState(false);

  const onOpen = () => setCompareOpen(true);
  const onClose = () => setCompareOpen(false);

  return (
    <>
      <Dropdown.Item onClick={onOpen}>
        {t("multi.rooms.ended.compareResults")}
      </Dropdown.Item>
      <Offcanvas
        placement="end"
        show={isCompareOpen}
        onHide={onClose}
        className="RoomCompareResultsContainer"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {t("multi.rooms.ended.compareResults")}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <RoomCompareResults players={players} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
