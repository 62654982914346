import { addDoc, collection } from "firebase/firestore";

import { converter } from "../helpers/collections";
import { roomsCollection } from "./rooms";

export const roomMessagesCollection = (roomId) =>
  collection(roomsCollection, roomId, "messages").withConverter(converter);

export async function createRoomMessage({
  roomId,
  playerId,
  username,
  content,
}) {
  const data = {
    username,
    createdBy: playerId,
    createdAt: Date.now(),
    content,
  };

  const ref = roomMessagesCollection(roomId);
  return await addDoc(ref, data);
}
