import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import Map from "@arcgis/core/Map";
import Ground from "@arcgis/core/Ground";

import { themeState } from "../../state/game/theme";

export function useMap(refs) {
  const theme = useRecoilValue(themeState);

  useEffect(() => {
    refs.current.map = new Map({
      basemap: null,
      ground: null,
    });
  }, [refs]);

  useEffect(() => {
    refs.current.map.ground = new Ground({
      surfaceColor: theme.waterColor,
    });
  }, [refs, theme]);
}
