import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Language } from "../../constants/language";
import { ThemePicker } from "./ThemePicker";
import { Settings } from "./Settings";
import { About } from "./About";
import { Help } from "./Help";

import "./Toolbar.scss";

export function Toolbar() {
  const { i18n } = useTranslation();

  const setLanguage = async (language) => {
    try {
      await i18n.changeLanguage(language);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="Toolbar">
      <ButtonToolbar>
        <ButtonGroup vertical className="mb-2" size="sm">
          <ThemePicker />
          <Settings />
        </ButtonGroup>
        <ButtonGroup vertical className="mb-2" size="sm">
          <Button
            variant="light"
            active={i18n.language === Language.en}
            onClick={() => setLanguage(Language.en)}
          >
            <span className="fi fi-us" />
          </Button>
          <Button
            variant="light"
            active={i18n.language === Language.fr}
            onClick={() => setLanguage(Language.fr)}
          >
            <span className="fi fi-fr" />
          </Button>
          <Button
            variant="light"
            active={i18n.language === Language.es}
            onClick={() => setLanguage(Language.es)}
          >
            <span className="fi fi-es" />
          </Button>
        </ButtonGroup>
        <ButtonGroup vertical size="sm">
          <About />
          <Help />
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
}
