import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { roomsCollection } from "../../collections/rooms";
import { auth } from "../../firebase";

export function useRoom() {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [room, isLoading, error] = useDocumentData(doc(roomsCollection, id));

  return {
    isLoading,
    error,
    room: room ? { ...room, id } : null,
    user,
  };
}
