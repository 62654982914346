import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";

import { AuthTab } from "../../constants/auth";
import { auth } from "../../firebase";
import { shouldRequestAuthState } from "../../state/users/auth";

export function useAuthModal() {
  const [user] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldRequestAuth, setShouldRequestAuth] = useRecoilState(
    shouldRequestAuthState
  );
  const defaultTab = auth.currentUser?.uid
    ? AuthTab.upgradeAccount
    : AuthTab.signUp;
  const initialTab = location.state?.tab || defaultTab;
  const [tab, setTab] = useState(initialTab);

  const tabs = auth.currentUser?.uid
    ? [AuthTab.upgradeAccount]
    : Object.values(AuthTab).filter((tab) => tab !== AuthTab.upgradeAccount);

  const onHide = useCallback(() => {
    if (shouldRequestAuth.value) {
      setShouldRequestAuth({ value: false, nextPage: null });
    } else {
      const origin = user?.uid ? location.state?.origin : null;
      navigate(origin || "/");
    }
  }, [
    location.state?.origin,
    navigate,
    setShouldRequestAuth,
    shouldRequestAuth.value,
    user?.uid,
  ]);

  return { tabs, tab, setTab, onHide };
}
