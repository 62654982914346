import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";

import { profileAchievementsCollection } from "../../collections/achievements";
import { getCompletionAchievementName } from "../../helpers/achievements";
import { DifficultyRule } from "../../constants/rules";

export function useProfileCompletionAchievements(mode, allowTypos) {
  const { id } = useParams();

  const isExtendedMode = mode.startsWith("extended");
  const isCityMode = mode.endsWith("Cities");
  const difficulties = Object.values(DifficultyRule);

  const getName = (difficulty) =>
    getCompletionAchievementName(
      isExtendedMode,
      isCityMode,
      difficulty,
      allowTypos
    );

  const [achievements, isLoading, error] = useCollectionData(
    query(
      profileAchievementsCollection(id),
      where(
        "name",
        "in",
        difficulties.map((difficulty) => getName(difficulty))
      )
    )
  );

  const foundNames = useMemo(
    () =>
      achievements
        ? new Set(achievements.map((achievement) => achievement.name))
        : new Set(),
    [achievements]
  );

  return { isLoading, error, foundNames, difficulties, getName };
}
