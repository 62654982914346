import { useUserInfo } from "../../hooks/users/useUserInfo";
import { Username } from "../user/Username";
import { FormattedTime } from "../shared/FormattedTime";
import { ScoreMedal } from "./ScoreMedal";
import { formatElapsedTime } from "../../helpers/timer";
import { useMedia } from "react-use";

export function ListScoreRow({ rank, score }) {
  const userInfo = useUserInfo(score.createdBy);
  const isSmall = useMedia("(width <= 600px)");

  return (
    <tr>
      <td className="number col-sm-1 text-nowrap">
        {rank}
        {rank <= 3 && <ScoreMedal />}
      </td>
      {isSmall ? (
        <td>
          <Username
            id={score.createdBy}
            value={score.createdByUsername}
            {...userInfo}
          />
          <br />
          <FormattedTime value={score.createdAt} />
        </td>
      ) : (
        <>
          <td>
            <Username
              id={score.createdBy}
              value={score.createdByUsername}
              {...userInfo}
            />
          </td>
          <td>
            <FormattedTime value={score.createdAt} />
          </td>
        </>
      )}
      <td className="text-end number col-sm-1 text-nowrap">
        <span className="text-muted">
          {formatElapsedTime(score.elapsedTime, score.rules.timer)}
        </span>{" "}
        {score.value}
      </td>
    </tr>
  );
}
