import { useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { createAchievement } from "../../collections/achievements";
import { auth } from "../../firebase";

export function useWriteAchievements() {
  const [user] = useAuthState(auth);

  return useCallback(
    async (partialData) => {
      try {
        await createAchievement({ ...partialData, createdBy: user?.uid });
      } catch (error) {
        console.error(error);
      }
    },
    [user?.uid]
  );
}
