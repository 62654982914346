import { useCallback, useMemo, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import { isCityModeState, isExtendedModeState } from "../../state/game/rules";
import { allCodesState } from "../../state/results/allCodes";
import { getAllContinentCodes } from "../../helpers/codes";
import { allCountriesPerCode } from "../../constants/datasets/countries";
import { getContinentAchievementName } from "../../helpers/achievements";
import { useNotifications } from "../notifications/useNotifications";
import { unsavedAchievementsState } from "../../state/users/achievements";
import { useWriteAchievements } from "./useWriteAchievements";
import { auth } from "../../firebase";
import { hasAchievement } from "../../collections/achievements";

export function useContinentsAchievements() {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const { notify } = useNotifications();
  const isCityMode = useRecoilValue(isCityModeState);
  const isExtendedMode = useRecoilValue(isExtendedModeState);
  const allCodes = useRecoilValue(allCodesState);
  const setUnsavedAchievements = useSetRecoilState(unsavedAchievementsState);
  const achievementsRef = useRef(new Set());
  const totalFoundCodesPerContinent = useRef(new Map());
  const totalCodesPerContinent = useMemo(
    () => getTotalCodesPerContinent(allCodes),
    [allCodes]
  );
  const writeAchievement = useWriteAchievements();

  const onSuccess = useCallback(
    (name, continentCode) => {
      const continent = t(`game.results.continents.${continentCode}`);
      notify({
        title: t("notifications.continentAchievementUnlocked.title"),
        message: t("notifications.continentAchievementUnlocked.message", {
          continent,
        }),
        autoHideAfterSeconds: 15,
      });

      const partialData = { name, createdAt: Date.now() };

      if (user?.uid) {
        writeAchievement(partialData).catch(console.error);
      } else {
        setUnsavedAchievements((achievements) => [
          ...achievements,
          partialData,
        ]);
      }
    },
    [notify, setUnsavedAchievements, t, user?.uid, writeAchievement]
  );

  return useCallback(
    (foundCodes) => {
      foundCodes.forEach((foundCode) => {
        incrementCounter(totalFoundCodesPerContinent.current, foundCode);
      });

      getAllContinentCodes(isExtendedMode)
        .map((continentCode) => ({
          continentCode,
          name: getContinentAchievementName(
            isExtendedMode,
            isCityMode,
            continentCode
          ),
        }))
        .filter(({ name }) => !achievementsRef.current.has(name))
        .forEach(({ continentCode, name }) => {
          if (
            totalCodesPerContinent.get(continentCode) ===
            totalFoundCodesPerContinent.current.get(continentCode)
          ) {
            achievementsRef.current.add(name);

            hasAchievement(user?.uid, name)
              .then((res) => {
                if (!res) {
                  onSuccess(name, continentCode);
                }
              })
              .catch(console.error);
          }
        });
    },
    [isCityMode, isExtendedMode, onSuccess, totalCodesPerContinent, user?.uid]
  );
}

function getTotalCodesPerContinent(codes) {
  const map = new Map();

  codes.forEach((code) => {
    incrementCounter(map, code);
  });

  return map;
}

function incrementCounter(map, code) {
  const country = allCountriesPerCode.get(code);
  const continent = country.continent;

  if (map.has(continent)) {
    const newValue = map.get(continent) + 1;
    map.set(continent, newValue);
  } else {
    map.set(continent, 1);
  }
}
