import { useRoomEnded } from "../../../hooks/rooms/useRoomEnded";
import { Game } from "../../game/Game";
import { RoomEndedActions } from "./RoomEndedActions";

export function RoomEnded({ room, user, players, player }) {
  const { isMounted } = useRoomEnded(room, user, player);

  return (
    isMounted && (
      <Game endedGameActions={<RoomEndedActions players={players} />} />
    )
  );
}
