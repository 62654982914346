import clsx from "clsx";

import { AchievementsContinentsBadges } from "./AchievementsContinentsBadges";
import { AchievementsCompletionBadges } from "./AchievementsCompletionBadges";
import { ProfileUpgradeWarning } from "./ProfileUpgradeWarning";

import "./AchivementsTab.scss";

export function AchievementsTab({ profile, mode }) {
  return (
    <>
      <ProfileUpgradeWarning profile={profile} className="mb-3" />
      <div className={clsx("Achievements", !profile.isUpgraded && "locked")}>
        <AchievementsContinentsBadges mode={mode} />
        <hr className="pb-3" />
        <AchievementsCompletionBadges mode={mode} allowTypos />
        <hr className="pb-3" />
        <AchievementsCompletionBadges mode={mode} allowTypos={false} />
      </div>
    </>
  );
}
