import { useCallback, useState } from "react";

export function useCopy(value, delay = 2000) {
  const [id, setId] = useState(null);

  const onCopy = useCallback(async () => {
    if (id) {
      clearInterval(id);
    }

    try {
      await navigator.clipboard.writeText(value);
    } catch (error) {
      console.error(error);
    }

    const newId = setTimeout(() => {
      setId(null);
    }, delay);

    setId(newId);

    return () => {
      clearInterval(newId);
    };
  }, [delay, id, value]);

  return { hasCopied: !!id, onCopy };
}
