import { useCallback } from "react";
import { useResetRecoilState } from "recoil";

import { activeCodeState } from "../../state/game/activeCode";
import { statusState } from "../../state/game/status";
import { startedAtState } from "../../state/game/startedAt";
import { endedAtState } from "../../state/game/endedAt";
import { foundCodesState } from "../../state/game/foundCodes";
import {
  areResultsSavedState,
  areResultsSavingState,
} from "../../state/users/results";

export function useOnReset() {
  const resetActiveCode = useResetRecoilState(activeCodeState);
  const resetStatus = useResetRecoilState(statusState);
  const resetStartedAt = useResetRecoilState(startedAtState);
  const resetEndedAt = useResetRecoilState(endedAtState);
  const resetFoundCodes = useResetRecoilState(foundCodesState);
  const resetAreResultsSaving = useResetRecoilState(areResultsSavingState);
  const resetAreResultsSaved = useResetRecoilState(areResultsSavedState);

  return useCallback(() => {
    resetActiveCode();
    resetAreResultsSaved();
    resetAreResultsSaving();
    resetEndedAt();
    resetFoundCodes();
    resetStartedAt();
    resetStatus();
  }, [
    resetActiveCode,
    resetAreResultsSaved,
    resetAreResultsSaving,
    resetEndedAt,
    resetFoundCodes,
    resetStartedAt,
    resetStatus,
  ]);
}
