import { Card, Col, Row } from "react-bootstrap";

import { EditProfileForm } from "../../forms/EditProfileForm";

export function EditTab({ profile, userInfo }) {
  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Body>
            <EditProfileForm
              defaultValues={{
                username: profile.username,
                country: userInfo.country,
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
