import { collection, doc, setDoc, updateDoc } from "firebase/firestore";

import { converter } from "../helpers/collections";
import { roomsCollection } from "./rooms";

export const roomPlayersCollection = (roomId) =>
  collection(roomsCollection, roomId, "players").withConverter(converter);

export async function createRoomPlayer({
  roomId,
  playerId,
  username,
  isReady,
}) {
  const now = Date.now();
  const data = {
    username,
    createdBy: playerId,
    createdAt: now,
    updatedAt: now,
    isReady,
    foundCodes: [],
  };

  const ref = doc(roomPlayersCollection(roomId), playerId);
  await setDoc(ref, data);
  return { ...data, id: playerId };
}

export async function updateRoomPlayer({ roomId, playerId, data }) {
  const ref = doc(roomPlayersCollection(roomId), playerId);
  await updateDoc(ref, { ...data, updatedAt: Date.now() });
}
