import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useNewRoomForm } from "../hooks/forms/useNewRoomForm";

export function NewRoomForm() {
  const { t } = useTranslation();
  const { name, isPrivate, onNameChange, onIsPrivateChange } = useNewRoomForm();

  return (
    <>
      <Form.Group controlId="name" className="mb-3">
        <Form.Label>{t("app.roomName")}</Form.Label>
        <Form.Control
          autoFocus
          required
          value={name}
          maxLength={40}
          placeholder="My room"
          autoComplete="off"
          onChange={onNameChange}
        />
      </Form.Group>
      <Form.Check
        id="private"
        type="switch"
        label={t("forms.newRoom.private.label")}
        checked={isPrivate}
        onChange={onIsPrivateChange}
      />
    </>
  );
}
