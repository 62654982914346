import { auth } from "../../firebase";
import { useTrackUserConnections } from "../users/useTrackUserConnections";
import { useAutosaveScores } from "../users/useAutosaveScores";
import { useAutosaveAchievements } from "../achievements/useAutosaveAchievements";
import { useDefaultRoomName } from "../rooms/useDefaultRoomName";

export function useApp() {
  useTrackUserConnections(auth);
  useAutosaveScores();
  useAutosaveAchievements();
  useDefaultRoomName();
}
