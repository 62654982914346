import { useRecoilState } from "recoil";

import { rulesState } from "../../state/game/rules";
import { extendedCodes, mainCodes } from "../../constants/datasets/countries";

export function useRulesForm() {
  const [rules, setRules] = useRecoilState(rulesState);

  const totalMainCodes = mainCodes.size;
  const totalExtendedCodes = extendedCodes.size;

  const onSelectChange = (ruleName) => (event) => {
    setRules((rules) => ({ ...rules, [ruleName]: event.target.value }));
  };

  const onSwitchChange = (ruleName) => (event) => {
    setRules((rules) => ({ ...rules, [ruleName]: event.target.checked }));
  };

  return {
    rules,
    totalMainCodes,
    totalExtendedCodes,
    onSelectChange,
    onSwitchChange,
  };
}
