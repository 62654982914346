import { atom, selector } from "recoil";

import { defaultRules } from "../../constants/rules";

export const rulesState = atom({
  key: "rulesState",
  default: defaultRules,
});

export const isCityModeState = selector({
  key: "isCityModeState",
  get: ({ get }) => {
    const rules = get(rulesState);
    return rules.mode.endsWith("Cities");
  },
});

export const isExtendedModeState = selector({
  key: "isExtendedModeState",
  get: ({ get }) => {
    const rules = get(rulesState);
    return rules.mode.startsWith("extended");
  },
});
