import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useGameResultsCard } from "../../../hooks/game/useGameResultsCard";
import { rulesState } from "../../../state/game/rules";

export function ResultsCard() {
  const { t } = useTranslation();
  const { progress, elapsedTime, isSuccess } = useGameResultsCard();
  const rules = useRecoilValue(rulesState);

  const bg = isSuccess ? "success" : "danger";
  const key = isSuccess ? "success" : "failure";
  const values = { ...progress, elapsedTime };

  return (
    <Card bg={bg} text="light" className="mb-2">
      <Card.Header>{t(`game.ended.${key}.title`)}</Card.Header>
      <Card.Body>
        <Card.Text>
          {t(`game.ended.${key}.text.${rules.mode}`, values)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
