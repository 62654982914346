import { useTranslation } from "react-i18next";
import { Accordion, Alert, Form, Modal } from "react-bootstrap";

import { useJoinRoom } from "../../../hooks/rooms/useJoinRoom";
import { RulesFilterForm } from "../../../forms/RulesFilterForm";
import { ListRooms } from "./ListRooms";

export function JoinRoom() {
  const { t } = useTranslation();
  const {
    filtersOn,
    isLoading,
    error,
    filters,
    search,
    rooms,
    onFiltersOnChange,
    onSelectChange,
    onSwitchChange,
    onSearchChange,
    onHide,
  } = useJoinRoom();

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      size="lg"
      fullscreen="lg-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("app.joinRoom")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          defaultActiveKey="search"
          activeKey={filtersOn ? "on" : null}
        >
          <Accordion.Item eventKey="on">
            <Accordion.Header onClick={onFiltersOnChange}>
              {t("app.filters")}
            </Accordion.Header>
            <Accordion.Body>
              <RulesFilterForm
                value={filters}
                onSelectChange={onSelectChange}
                onSwitchChange={onSwitchChange}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {!!error && (
          <Alert className="mt-3" variant="danger">
            {error.toString()}
          </Alert>
        )}
        <Form.Control
          autoFocus
          value={search}
          placeholder={t("app.search")}
          type="text"
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          autoSave="off"
          spellCheck={false}
          data-lpignore="true"
          data-form-type="other"
          onChange={onSearchChange}
          className="mt-3"
        />
        <ListRooms filters={filters} isLoading={isLoading} rooms={rooms} />
      </Modal.Body>
    </Modal>
  );
}
