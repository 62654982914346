import { DifficultyRule } from "../constants/rules";

export function getContinentAchievementName(
  isExtendedMode,
  isCityMode,
  continentCode
) {
  const id = isExtendedMode ? 1 : 2;

  return [
    isCityMode ? "cities" : "territories",
    "continents",
    `${continentCode.toLowerCase()}-${id}`,
  ].join("/");
}

export function getCompletionAchievementName(
  isExtendedMode,
  isCityMode,
  difficulty,
  allowTypos
) {
  const ss = allowTypos ? "off" : "on";
  const mode = isExtendedMode ? "x" : "m";
  const idsPerDifficulty = {
    [DifficultyRule.hiddenTerritories]: 1,
    [DifficultyRule.hiddenBorders]: 2,
    [DifficultyRule.visibleBorders]: 3,
  };

  return [
    isCityMode ? "cities" : "territories",
    `ss-${ss}`,
    `${mode}-${idsPerDifficulty[difficulty]}`,
  ].join("/");
}
