import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useEditProfileForm } from "../hooks/forms/useEditProfileForm";
import { CountryInput } from "../inputs/CountryInput";

export function EditProfileForm({ defaultValues }) {
  const { t } = useTranslation();
  const state = useEditProfileForm(defaultValues);

  return (
    <Form onSubmit={state.onSubmit} validated={state.isValidated}>
      {!!state.error && (
        <Alert variant="danger">{state.error.toString()}</Alert>
      )}
      <Form.Group controlId="username" className="mb-3">
        <Form.Label>{t("forms.auth.username.label")}</Form.Label>
        <Form.Control
          required
          autoFocus
          autoComplete="off"
          minLength={3}
          maxLength={25}
          value={state.values.username}
          placeholder="Bob"
          onChange={state.onInputChange("username")}
        />
        <Form.Text muted>{t("forms.auth.username.help")}</Form.Text>
      </Form.Group>
      <CountryInput
        value={state.values.country}
        onChange={state.onSelectChange("country")}
      />
      <Button variant="primary" type="submit" disabled={state.isLoading}>
        {state.isLoading ? t("app.isLoading") : t("app.save")}
      </Button>
    </Form>
  );
}
