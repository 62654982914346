import { extendedCodes, mainCodes } from "../constants/datasets/countries";
import { continentCodes } from "../constants/datasets/continents";
import { ModeRule } from "../constants/rules";

export function getAllContinentCodes(isExtendedMode) {
  return isExtendedMode
    ? continentCodes
    : continentCodes.filter((code) => code !== "AN");
}

export function getAllCodes(mode) {
  switch (mode) {
    case ModeRule.mainCountries:
    case ModeRule.capitalCities:
      return mainCodes;
    case ModeRule.extendedTerritories:
    case ModeRule.extendedCities:
      return extendedCodes;
    default:
      return new Set();
  }
}
