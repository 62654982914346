import { useState } from "react";
import { Card, Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import { ProfileTab } from "../../constants/profiles";
import { ProfileActiveTab } from "./ProfileActiveTab";
import { auth } from "../../firebase";

export function ProfileBody({ profile, userInfo }) {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(ProfileTab.statistics);

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" activeKey={activeKey} onSelect={setActiveKey}>
          <Nav.Item>
            <Nav.Link eventKey={ProfileTab.statistics}>
              {t("pages.profile.tabs.statistics")}
            </Nav.Link>
          </Nav.Item>
          <NavDropdown
            title={t("pages.profile.tabs.achievements")}
            id="profile-tab-achievements"
            active={activeKey.startsWith("achievements")}
          >
            <NavDropdown.Item eventKey={ProfileTab.achievementsMainCountries}>
              {t("pages.profile.tabs.mainCountries")}
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={ProfileTab.achievementsCapitalCities}>
              {t("pages.profile.tabs.capitalCities")}
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey={ProfileTab.achievementsExtendedTerritories}
            >
              {t("pages.profile.tabs.extendedTerritories")}
            </NavDropdown.Item>
            <NavDropdown.Item eventKey={ProfileTab.achievementsExtendedCities}>
              {t("pages.profile.tabs.extendedCities")}
            </NavDropdown.Item>
          </NavDropdown>
          {profile.id === user?.uid && (
            <Nav.Item>
              <Nav.Link eventKey={ProfileTab.edit}>
                {t("pages.profile.tabs.edit")}
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </Card.Header>
      <Card.Body>
        <ProfileActiveTab
          activeKey={activeKey}
          profile={profile}
          userInfo={userInfo}
        />
      </Card.Body>
    </Card>
  );
}
