import levenshtein from "damerau-levenshtein";
import {
  SUGGESTION_MAX_RELATIVE,
  SUGGESTION_MIN_SIMILARITY,
} from "../constants/game";

export class ValidInputs {
  constructor() {
    this._head = new ValidInputNode();
    this._options = [];
  }

  static _getChars(normalizedValue) {
    return normalizedValue.split("");
  }

  _getDeepestNode(normalizedValue) {
    const chars = ValidInputs._getChars(normalizedValue);
    let node = this._head;

    while (chars.length) {
      const char = chars.shift();
      node = node.getChild(char);

      if (!node) {
        return null;
      }
    }

    return node;
  }

  _getSuggestions(normalizedValue, foundCodes) {
    const suggestions = [];

    this._options.forEach(({ code, name }) => {
      if (foundCodes.has(code) || name.startsWith(normalizedValue)) {
        return;
      }

      const result = levenshtein(normalizedValue, name);

      if (
        result.similarity >= SUGGESTION_MIN_SIMILARITY &&
        result.relative <= SUGGESTION_MAX_RELATIVE
      ) {
        suggestions.push({ result, name });
      }
    });

    return suggestions;
  }

  register(name, code) {
    const chars = ValidInputs._getChars(name);
    this._head.register(chars, code);
    this._options.push({ name, code });
  }

  getSuggestion(normalizedValue, foundCodes) {
    const suggestions = this._getSuggestions(normalizedValue, foundCodes);

    if (suggestions.length > 1) {
      suggestions.sort(({ result: a }, { result: b }) =>
        a.steps === b.steps
          ? a.similarity === b.similarity
            ? a.relative - b.relative
            : b.similarity === a.similarity
          : b.steps - a.steps
      );
    }

    return suggestions.length ? suggestions[0].name : null;
  }

  query(normalizedValue) {
    const node = this._getDeepestNode(normalizedValue);

    return {
      matches: node?.getMatchingCodes() || [],
      futureMatches: node?.getFutureMatches() || [],
    };
  }
}

class ValidInputNode {
  constructor() {
    this._children = new Map();
    this._matchingCodes = [];
  }

  register(chars, code) {
    if (!chars.length) {
      this._matchingCodes.push(code);
      return;
    }

    const char = chars.shift();

    if (!this._children.has(char)) {
      this._children.set(char, new ValidInputNode());
    }

    this._children.get(char).register(chars, code);
  }

  getChild(char) {
    return this._children.get(char);
  }

  getChildren() {
    return Array.from(this._children.values());
  }

  getMatchingCodes() {
    return this._matchingCodes;
  }

  getFutureMatches() {
    const codes = [];
    const queue = Array.from(this._children.values());

    while (queue.length) {
      const child = queue.shift();
      codes.push(...child.getMatchingCodes());
      queue.push(...child.getChildren());
    }

    return codes;
  }
}
