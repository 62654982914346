import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { rulesState } from "../../state/game/rules";

export function useListRooms(filters) {
  const navigate = useNavigate();
  const location = useLocation();
  const setRules = useSetRecoilState(rulesState);

  const onCreate = useCallback(() => {
    setRules(filters);
    navigate("/multi/rooms/new", { state: { origin: location.pathname } });
  }, [filters, location.pathname, navigate, setRules]);

  return { onCreate };
}
