import { atom, selector } from "recoil";

import { rulesState } from "./rules";
import { ModeRule } from "../../constants/rules";
import { allCountriesPerCode } from "../../constants/datasets/countries";

const _activeCodeState = atom({
  key: "_activeCodeState",
  default: null,
});

export const activeCodeState = selector({
  key: "activeCodeState",
  default: null,
  get: ({ get }) => get(_activeCodeState),
  set: ({ set, get }, newValue) => {
    if (!newValue || typeof newValue !== "string") {
      set(_activeCodeState, newValue);
      return;
    }

    const rules = get(rulesState);

    switch (rules.mode) {
      case ModeRule.mainCountries:
      case ModeRule.capitalCities:
        const country = allCountriesPerCode.get(newValue);
        set(_activeCodeState, country.parent || newValue);
        break;
      default:
        set(_activeCodeState, newValue);
        break;
    }
  },
});
