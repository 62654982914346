import { Language } from "../../language";

import rawCountries from "./raw.json";
import englishNames from "./names/en.json";
import frenchNames from "./names/fr.json";
import spanishNames from "./names/es.json";

export const countryNamesByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.names)],
  [Language.es, new Map(spanishNames.names)],
  [Language.fr, new Map(frenchNames.names)],
]);

export const countryInformalNamesByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.informalNames)],
  [Language.es, new Map(spanishNames.informalNames)],
  [Language.fr, new Map(frenchNames.informalNames)],
]);

export const countryAbbreviationsByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.abbreviations)],
  [Language.es, new Map(spanishNames.abbreviations)],
  [Language.fr, new Map(frenchNames.abbreviations)],
]);

export const allCodes = new Set(
  rawCountries.map((rawCountry) => rawCountry.iso)
);

export const extendedCodes = new Set(
  rawCountries
    .filter((rawCountry) => rawCountry.isExtended)
    .map((rawCountry) => rawCountry.iso)
);

export const mainCodes = new Set(
  rawCountries
    .filter((rawCountry) => rawCountry.isMain)
    .map((rawCountry) => rawCountry.iso)
);

export const allCountriesPerCode = new Map(
  rawCountries.map((rawCountry) => [rawCountry.iso, rawCountry])
);
