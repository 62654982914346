import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { areLayersReadyState } from "../../../state/layers/ready";

export function useLayersReady({
  isAntarcticaLayerReady,
  isCountriesLayerReady,
  isCitiesLayerReady,
}) {
  const setLayersReady = useSetRecoilState(areLayersReadyState);

  useEffect(() => {
    setLayersReady(
      isAntarcticaLayerReady && isCountriesLayerReady && isCitiesLayerReady
    );
  }, [
    isAntarcticaLayerReady,
    isCountriesLayerReady,
    isCitiesLayerReady,
    setLayersReady,
  ]);
}
