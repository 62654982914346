import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export function About() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  return (
    <>
      <Button variant="light" tabIndex={0} onClick={onOpen}>
        <FontAwesomeIcon icon={faInfo} fixedWidth />
      </Button>
      <Modal show={show} onHide={onClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{t("toolbar.about")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            {t("toolbar.createdBy")}{" "}
            <Button
              href="https://www.instagram.com/samylmnr/"
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faInstagram} fixedWidth />
              samylmnr
            </Button>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
