import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";

import {
  isCityModeState,
  isExtendedModeState,
  rulesState,
} from "../../state/game/rules";
import { getCompletionAchievementName } from "../../helpers/achievements";
import { hasAchievement } from "../../collections/achievements";
import { auth } from "../../firebase";
import { useNotifications } from "../notifications/useNotifications";
import { useWriteAchievements } from "./useWriteAchievements";
import { unsavedAchievementsState } from "../../state/users/achievements";

export function useCompletionAchievements() {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const { notify } = useNotifications();
  const rules = useRecoilValue(rulesState);
  const isCityMode = useRecoilValue(isCityModeState);
  const isExtendedMode = useRecoilValue(isExtendedModeState);
  const setUnsavedAchievements = useSetRecoilState(unsavedAchievementsState);
  const writeAchievement = useWriteAchievements();

  const onSuccess = useCallback(
    (name) => {
      notify({
        title: t("notifications.completionAchievementUnlocked.title"),
        message: t("notifications.completionAchievementUnlocked.message"),
        autoHideAfterSeconds: 15,
      });

      const partialData = { name, createdAt: Date.now() };

      if (user?.uid) {
        writeAchievement(partialData).catch(console.error);
      } else {
        setUnsavedAchievements((achievements) => [
          ...achievements,
          partialData,
        ]);
      }
    },
    [notify, setUnsavedAchievements, t, user?.uid, writeAchievement]
  );

  return useCallback(() => {
    const name = getCompletionAchievementName(
      isExtendedMode,
      isCityMode,
      rules.difficulty,
      rules.allowTypos
    );

    hasAchievement(user?.uid, name)
      .then((res) => {
        if (!res) {
          onSuccess(name);
        }
      })
      .catch(console.error);
  }, [
    isCityMode,
    isExtendedMode,
    onSuccess,
    rules.difficulty,
    rules.allowTypos,
    user?.uid,
  ]);
}
