import { selector } from "recoil";

import { rulesState } from "../game/rules";
import { getAllCodes } from "../../helpers/codes";

export const allCodesState = selector({
  key: "allCodesState",
  get: ({ get }) => {
    const rules = get(rulesState);
    return getAllCodes(rules.mode);
  },
});
