import { useTranslation } from "react-i18next";

import { authFormState } from "../../../state/forms/auth";
import { useForm } from "../../shared/useForm";
import { signUp } from "../../../collections/users";
import { useAuthSuccess } from "./useAuthSuccess";

export function useSignUpForm() {
  const { t } = useTranslation();

  const onSuccess = useAuthSuccess({
    title: t("notifications.accountCreated.title"),
    message: t("notifications.accountCreated.message"),
    autoHideAfterSeconds: 10,
  });

  return useForm(authFormState, signUp, onSuccess);
}
