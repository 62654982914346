import { selector } from "recoil";

import { isCityModeState, rulesState } from "../../game/rules";
import { statusState } from "../../game/status";
import { foundCodesState } from "../../game/foundCodes";
import { DifficultyRule } from "../../../constants/rules";
import { GameStatus } from "../../../constants/game";
import { allCodes } from "../../../constants/datasets/countries";
import { allCodesState } from "../../results/allCodes";

export const visibleCitiesState = selector({
  key: "visibleCitiesState",
  get: ({ get }) => {
    const rules = get(rulesState);
    const gameStatus = get(statusState);
    const foundCodes = get(foundCodesState);
    const allGameCodes = get(allCodesState);
    const isCityMode = get(isCityModeState);

    if (!isCityMode) {
      return [];
    }

    const isDifficultyHard =
      rules.difficulty === DifficultyRule.hiddenTerritories;
    const hasGameEnded = gameStatus === GameStatus.ended;
    const shouldHideRemaining = isDifficultyHard && !hasGameEnded;

    const codes = [];
    allCodes.forEach((code) => {
      if (!allGameCodes.has(code)) {
        return;
      }

      if (!shouldHideRemaining || foundCodes.has(code)) {
        codes.push(code);
      }
    });

    return codes;
  },
});
