import { atom } from "recoil";

import { persistAtom } from "../../helpers/state";

export const isChatOpenState = atom({
  key: "isChatOpenState",
  default: false,
});

export const isChatMutedState = atom({
  key: "isChatMutedState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
