import { Spinner as BootstrapSpinner } from "react-bootstrap";
import clsx from "clsx";

export function Spinner({ className = null }) {
  return (
    <div className={clsx("w-100 text-center", className)}>
      <BootstrapSpinner />
    </div>
  );
}
