import { useTranslation } from "react-i18next";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { useGameResult } from "../../../hooks/game/useGameResult";
import { SharableResults } from "./SharableResults";
import { ResultsCard } from "./ResultsCard";

export function GameResult({ actions }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useGameResult();

  return (
    <>
      <ResultsCard />
      <div className="mt-3 d-flex gap-3 justify-content-center flex-wrap">
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            variant="primary"
            size="lg"
            id="results-dropdown"
            tabIndex={0}
            autoFocus
          >
            {t("app.results")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {actions}
            <Dropdown.Item disabled={state.isLoading} onClick={state.onShare}>
              {state.isLoading
                ? t("app.isLoading")
                : t("game.ended.shareResults")}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={state.isSaveDisabled}
              onClick={state.onSaveResults}
            >
              {state.areResultsSaving
                ? t("app.isLoading")
                : t("game.ended.saveResults")}
              {state.areResultsSaving ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="ms-2 fa-spin"
                />
              ) : state.areResultsSaved ? (
                <FontAwesomeIcon icon={faCheck} className="ms-2 text-success" />
              ) : null}
            </Dropdown.Item>
            <Dropdown.Item onClick={state.onTrackProgress}>
              {t("game.ended.trackProgress")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button variant="light" size="lg" onClick={() => navigate("/")}>
          {t("game.ended.playAgain")}
        </Button>
      </div>
      <div className="AppContentOffScreen">
        <SharableResults innerRef={state.sharableResultsRef} />
      </div>
    </>
  );
}
