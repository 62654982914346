import { Language } from "../../language";

import englishNames from "./names/en.json";
import frenchNames from "./names/fr.json";
import spanishNames from "./names/es.json";

export const cityNamesByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.names)],
  [Language.es, new Map(spanishNames.names)],
  [Language.fr, new Map(frenchNames.names)],
]);

export const cityInformalNamesByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.informalNames)],
  [Language.es, new Map(spanishNames.informalNames)],
  [Language.fr, new Map(frenchNames.informalNames)],
]);

export const cityAbbreviationsByCodeByLanguage = new Map([
  [Language.en, new Map(englishNames.abbreviations)],
  [Language.es, new Map(spanishNames.abbreviations)],
  [Language.fr, new Map(frenchNames.abbreviations)],
]);
