import { useTranslation } from "react-i18next";

import { Profile } from "../components/profile/Profile";
import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";

export function ProfilePage() {
  const { t } = useTranslation();
  useDocumentTitle(t("pages.profile.title"));

  return <Profile />;
}
