import { useCallback } from "react";
import { useRecoilState } from "recoil";

import { notificationsState } from "../../state/notifications/notifications";

export function useNotifications() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const notify = useCallback(
    ({ title, message, autoHideAfterSeconds = null, variant = "light" }) => {
      const now = Date.now();

      const notification = {
        id: now,
        autoHide: autoHideAfterSeconds !== null,
        delay: autoHideAfterSeconds ? autoHideAfterSeconds * 1000 : null,
        variant,
        title,
        createdAt: now,
        message,
      };

      setNotifications((notifications) => [...notifications, notification]);
    },
    [setNotifications]
  );

  const onClose = useCallback((id) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id)
    );
  }, [setNotifications]);

  return { notifications, notify, onClose };
}
