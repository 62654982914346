import { selector } from "recoil";

import { activeCodeState } from "../game/activeCode";
import { allCountriesPerCode } from "../../constants/datasets/countries";

export const activeContinentState = selector({
  key: "activeContinentState",
  get: ({ get }) => {
    const activeCode = get(activeCodeState);
    if (!activeCode) {
      return null;
    }

    return allCountriesPerCode.get(activeCode).continent;
  },
});
