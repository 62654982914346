import { Accordion, Alert, Badge, Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import stringToColor from "string-to-color";
import { useTranslation } from "react-i18next";
import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilValue } from "recoil";
import clsx from "clsx";

import { Username } from "../../user/Username";
import { usePlayerInfoById } from "../../../hooks/users/usePlayerInfoById";
import { useRoomChat } from "../../../hooks/rooms/useRoomChat";
import { Spinner } from "../../shared/Spinner";
import { statusState } from "../../../state/game/status";
import { GameStatus } from "../../../constants/game";
import { FormattedTime } from "../../shared/FormattedTime";

import "./RoomChat.scss";

export function RoomChat({ room, user, playersValues }) {
  const { t } = useTranslation();
  const gameStatus = useRecoilValue(statusState);
  const state = useRoomChat(room, user);
  const playerInfoById = usePlayerInfoById(playersValues.players);

  return (
    <div className={clsx("RoomChat", state.isOpen && "open")}>
      <Accordion activeKey={state.isOpen ? "chat" : null}>
        <Accordion.Item eventKey="chat">
          <Accordion.Header onClick={state.onToggle}>
            {t(`multi.chat.title`)}
            {!!state.newMessagesCounter && (
              <Badge pill className="ms-2">
                {state.newMessagesCounter}
              </Badge>
            )}
            <FontAwesomeIcon
              icon={state.isMuted ? faVolumeMute : faVolumeHigh}
              className="ms-auto me-0"
              onClick={state.onMute}
              onKeyDown={state.onMute}
              tabIndex={0}
              role="button"
            />
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="Messages px-3 py-2">
              {state.areMessagesLoading ? (
                <Spinner />
              ) : state.messages?.length ? (
                state.messages?.map((message) => (
                  <div key={message.id} className="my-1">
                    <p className="m-0">
                      <small className="text-muted">
                        {<FormattedTime value={message.createdAt} />}:
                      </small>
                    </p>
                    <p className="m-0">
                      <strong
                        style={{ color: stringToColor(message.createdBy) }}
                      >
                        <Username
                          id={message.createdBy}
                          value={message.username}
                          {...playerInfoById.get(message.createdBy)}
                          tooltipPlacement="left"
                          icon={false}
                          clickable={gameStatus === GameStatus.ended}
                        />
                        :
                      </strong>{" "}
                      {message.content}
                    </p>
                  </div>
                ))
              ) : (
                <span className="text-muted">{t(`multi.chat.empty`)}</span>
              )}
              {!!state.error && (
                <Alert variant="danger" className="my-2">
                  {state.error.toString()}
                </Alert>
              )}
              <div ref={state.messagesEndRef} />
            </div>
            <Form onSubmit={state.onSubmit}>
              <InputGroup>
                <Form.Control
                  ref={state.inputRef}
                  value={state.value}
                  className="ChatInput"
                  size="lg"
                  placeholder={t(`multi.chat.placeholder`)}
                  type="text"
                  autoCorrect="off"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoSave="off"
                  spellCheck={false}
                  tabIndex={0}
                  maxLength={140}
                  data-lpignore="true"
                  data-form-type="other"
                  onChange={state.onChange}
                />
                <Button
                  type="submit"
                  disabled={state.isSending || !state.value}
                  className="SendButton"
                >
                  {t(`multi.chat.send`)}
                </Button>
              </InputGroup>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
