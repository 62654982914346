import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

import { rulesState } from "../../state/game/rules";
import { scoresCollection } from "../../collections/scores";
import { SCORES_PER_PAGE } from "../../constants/leaderboards";
import { getLeaderboardId } from "../../collections/leaderboards";

export function useLeaderboard(defaultFilters = null, onClose = null) {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [lastDocs, setLastDocs] = useState([]);
  const rules = useRecoilValue(rulesState);
  const [filters, setFilters] = useState(defaultFilters || rules);
  const [leaderboardId, setLeaderboardId] = useState(getLeaderboardId());
  const setRules = useSetRecoilState(rulesState);

  const constraints = [limit(SCORES_PER_PAGE)];
  if (lastDocs.length) {
    const lastDoc = lastDocs[lastDocs.length - 1];
    constraints.unshift(startAfter(lastDoc.snapshot));
  }

  const [scores, isLoading, error] = useCollectionData(
    query(
      scoresCollection(leaderboardId),
      where("rules", "==", filters),
      orderBy("value", "desc"),
      orderBy("elapsedTime"),
      orderBy("createdAt"),
      orderBy("createdByUsername"),
      orderBy("createdBy"),
      ...constraints
    )
  );

  const onFirstPage = useCallback(() => {
    setLastDocs([]);
    setPage(1);
  }, []);

  const onPrevPage = useCallback(() => {
    setLastDocs((lastDocs) => lastDocs.slice(0, -1));
    setPage((page) => page - 1);
  }, []);

  const onNextPage = useCallback(() => {
    setLastDocs((lastDocs) => [...lastDocs, scores[scores.length - 1]]);
    setPage((page) => page + 1);
  }, [scores]);

  const totalPageDocs = scores?.length || 0;
  const hasMore = totalPageDocs >= SCORES_PER_PAGE;
  const pagination = {
    page,
    isDisabled: page === 1 && !hasMore,
    isFirstDisabled: isLoading || page === 1,
    isPrevDisabled: isLoading || !lastDocs.length,
    isNextDisabled: isLoading || !hasMore,
    onFirstPage,
    onPrevPage,
    onNextPage,
  };

  const onSelectChange = useCallback(
    (ruleName) => (event) => {
      setFilters((rules) => ({ ...rules, [ruleName]: event.target.value }));
      onFirstPage();
    },
    [onFirstPage, setFilters]
  );

  const onSwitchChange = useCallback(
    (ruleName) => (event) => {
      setFilters((rules) => ({ ...rules, [ruleName]: event.target.checked }));
      onFirstPage();
    },
    [onFirstPage, setFilters]
  );

  const onLeaderboardIdChange = useCallback(
    (event) => {
      setLeaderboardId(event.target.value);
      onFirstPage();
    },
    [onFirstPage]
  );

  const onHide = useCallback(() => {
    setRules(filters);

    if (onClose) {
      onClose();
    } else {
      const origin = location.state?.origin || "/";
      navigate(origin);
    }
  }, [filters, location.state?.origin, navigate, onClose, setRules]);

  return {
    isLoading,
    error,
    filters,
    leaderboardId,
    scores,
    pagination,
    onSelectChange,
    onSwitchChange,
    onLeaderboardIdChange,
    onHide,
  };
}
