import { onValue, ref, set } from "firebase/database";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  updateProfile as updateUser,
} from "firebase/auth";

import { auth, database } from "../firebase";
import { createProfile, updateProfile } from "./profiles";
import { createScore } from "./scores";
import { createResult } from "./results";

export async function signUp({ isAnon, username, country, email, password }) {
  const { user } = isAnon
    ? await signInAnonymously(auth)
    : await createUserWithEmailAndPassword(auth, email, password);

  await updateUser(user, { displayName: username });
  await createProfile({
    username,
    createdBy: user.uid,
    isUpgraded: !!email,
  });
  await setUserCountry(user.uid, country);

  return user;
}

export async function upgradeAccount({ email, password }) {
  const credential = EmailAuthProvider.credential(email, password);
  const { user } = await linkWithCredential(auth.currentUser, credential);
  await updateProfile(auth.currentUser.uid, { isUpgraded: true });

  return user;
}

export async function logIn({ email, password }) {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  return user;
}

export async function setUserCountry(userId, countryCode) {
  const countryRef = ref(database, `users/${userId}/country`);

  try {
    await set(countryRef, countryCode);
  } catch (error) {
    console.error(error);
  }
}

export async function editProfile({ username, country }) {
  const user = auth.currentUser;
  await updateUser(user, { displayName: username });
  await updateProfile(user.uid, { username });
  await setUserCountry(user.uid, country);
}

export async function resetPassword({ email }) {
  await sendPasswordResetEmail(auth, email);
}

export function onUserInfo(userId, url, callback) {
  const userRef = ref(database, `users/${userId}`);

  return onValue(userRef, (snapshot) => {
    const data = snapshot.val() || {};
    const connections = data.connections ? Object.values(data.connections) : [];
    const currentConnections = url
      ? connections.filter((connection) => connection.url === url)
      : connections;
    const isOnline = !!currentConnections.length;
    const isActive = currentConnections.some(
      (connection) => connection.isActive
    );

    callback({
      isOnline,
      isActive,
      isStaff: data.isStaff,
      isVip: data.isVip,
      country: data.country,
    });
  });
}

export async function saveResultAndScore({
  foundCodes,
  elapsedTime,
  rules,
  createdBy,
  createdByUsername,
  createdAt,
}) {
  await createResult({
    foundCodes,
    elapsedTime,
    rules,
    createdBy,
    createdAt,
  });

  return await createScore({
    value: foundCodes.length,
    elapsedTime,
    rules,
    createdBy,
    createdByUsername,
    createdAt,
  });
}
