import { selector } from "recoil";
import Color from "@arcgis/core/Color";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";

import { themeState } from "../../game/theme";

export const antarcticaRendererState = selector({
  key: "antarcticaRendererState",
  get: ({ get }) => {
    const theme = get(themeState);
    const color = Color.fromHex(theme.snowColor);

    return new SimpleRenderer({
      symbol: new SimpleFillSymbol({
        color,
        outline: { color, width: 1 },
      }),
    });
  },
});
