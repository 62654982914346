import { useState } from "react";
import { useHarmonicIntervalFn } from "react-use";

export function useNow(interval = 1000) {
  const [now, setNow] = useState(Date.now());

  useHarmonicIntervalFn(() => {
    setNow(Date.now());
  }, interval);

  return now;
}
