import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { useNotifications } from "../../notifications/useNotifications";
import { shouldRequestAuthState } from "../../../state/users/auth";
import { AuthNextPage } from "../../../constants/auth";

export function useAuthSuccess(notification) {
  const location = useLocation();
  const navigate = useNavigate();
  const { notify } = useNotifications();
  const [shouldRequestAuth, setShouldRequestAuth] = useRecoilState(
    shouldRequestAuthState
  );

  return useCallback(
    (user) => {
      if (shouldRequestAuth.value) {
        const nextPage =
          shouldRequestAuth.nextPage === AuthNextPage.myProfile
            ? `/profiles/${user.uid}`
            : shouldRequestAuth.nextPage;

        setShouldRequestAuth({ value: false, nextPage: null });
        nextPage &&
          navigate(nextPage, { state: { origin: location.pathname } });
      } else {
        const origin = location.state?.origin || "/";
        navigate(origin, { replace: true });
      }

      notify(notification);
    },
    [
      location.pathname,
      location.state?.origin,
      navigate,
      notification,
      notify,
      setShouldRequestAuth,
      shouldRequestAuth.nextPage,
      shouldRequestAuth.value,
    ]
  );
}
