import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { auth } from "../../firebase";
import { useLogOut } from "../../hooks/users/useLogOut";
import { AuthTab } from "../../constants/auth";

export function HomeAccountDropdownMenu() {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const onLogOut = useLogOut();

  if (!user) {
    return (
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => navigate("/auth", { state: { tab: AuthTab.signUp } })}
        >
          {t("auth.signUp")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => navigate("/auth", { state: { tab: AuthTab.logIn } })}
        >
          {t("auth.logIn")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            navigate("/auth", { state: { tab: AuthTab.resetPassword } })
          }
        >
          {t("auth.resetPassword")}
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  }

  return (
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => navigate(`/profiles/${user.uid}`)}>
        {t("pages.home.buttons.myProfile")}
      </Dropdown.Item>
      {!user.email && (
        <Dropdown.Item
          onClick={() =>
            navigate("/auth", { state: { tab: AuthTab.upgradeAccount } })
          }
        >
          {t("auth.upgradeAccount")}
        </Dropdown.Item>
      )}
      <Dropdown.Divider />
      <Dropdown.Item onClick={onLogOut}>
        {t("pages.home.buttons.logOut")}
      </Dropdown.Item>
    </Dropdown.Menu>
  );
}
