import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../firebase";
import { converter } from "../helpers/collections";

export const roomsCollection = collection(db, "rooms").withConverter(converter);

export async function createRoom({
  name,
  isPrivate,
  rules,
  createdBy,
  createdByUsername,
}) {
  const now = Date.now();

  return await addDoc(roomsCollection, {
    name,
    isPrivate,
    rules,
    createdBy,
    createdByUsername,
    createdAt: now,
    updatedAt: now,
    startedAt: null,
    endedAt: null,
  });
}

export async function updateRoom({ roomId, data }) {
  const ref = doc(roomsCollection, roomId);
  await updateDoc(ref, { ...data, updatedAt: Date.now() });
}

export async function deleteRoom(id) {
  const ref = doc(roomsCollection, id);
  await deleteDoc(ref);
}
