import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

import {
  allCodes,
  allCountriesPerCode,
  countryNamesByCodeByLanguage,
} from "../constants/datasets/countries";

export function CountryInput({ value, onChange, required = true }) {
  const { i18n, t } = useTranslation();

  const options = useMemo(
    () =>
      Array.from(allCodes).map((code) => ({
        value: code,
        label: countryNamesByCodeByLanguage.get(i18n.language).get(code)[0],
      })),
    [i18n.language]
  );

  return (
    <>
      <label htmlFor="country" className="form-label">
        {t("forms.auth.country.label")}
      </label>
      <Select
        inputId="country"
        name="country"
        required={required}
        className="mb-3"
        value={options.find((option) => option.value === value)}
        onChange={onChange}
        options={options}
        isSearchable
        placeholder={t("app.search")}
        components={{ Option: CustomOption }}
      />
    </>
  );
}

function CustomOption({ value, children, ...props }) {
  const flag = allCountriesPerCode.get(value).flag;

  return (
    <components.Option {...props}>
      <span className={`fi fi-${flag}`} /> {children}
    </components.Option>
  );
}
