import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { logEvent } from "firebase/analytics";

import { GameStatus } from "../../constants/game";
import { statusState } from "../../state/game/status";
import { startedAtState } from "../../state/game/startedAt";
import { isChatOpenState } from "../../state/rooms/chat";
import { analytics } from "../../firebase";

export function useStartGame() {
  const setStatus = useSetRecoilState(statusState);
  const setStartedAt = useSetRecoilState(startedAtState);
  const setIsChatOpen = useSetRecoilState(isChatOpenState);

  return useCallback(
    (now = Date.now()) => {
      setStatus(GameStatus.started);
      setStartedAt(now);
      setIsChatOpen(false);
      logEvent(analytics, "start");
    },
    [setIsChatOpen, setStartedAt, setStatus]
  );
}
