export const legacyTheme = {
  waterColor: null,
  groundColor: "#7f8c8d",
  foundColor: "#2ecc71",
  activeColor: "#3498db",
  missingColor: "#e74c3c",
  snowColor: "#ffffff",
  outlineColor: "#000000",
};

export const newTheme = {
  waterColor: "#004c73",
  groundColor: "#ffe39f",
  foundColor: "#29a634",
  activeColor: "#d1980b",
  missingColor: "#d33d17",
  snowColor: "#ffffff",
  outlineColor: "#000000",
};

export const matrixTheme = {
  waterColor: "#020204",
  groundColor: "#22b455",
  foundColor: "#80ce87",
  activeColor: "#92e5a1",
  missingColor: "#a14c4b",
  snowColor: "#ffffff",
  outlineColor: "#000000",
};

export const ThemeName = {
  legacyTheme: "legacyTheme",
  newTheme: "newTheme",
  matrixTheme: "matrixTheme",
};

export const themes = {
  [ThemeName.legacyTheme]: legacyTheme,
  [ThemeName.newTheme]: newTheme,
  [ThemeName.matrixTheme]: matrixTheme,
};

export const defaultThemeName = ThemeName.newTheme;
