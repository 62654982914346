import { useTranslation } from "react-i18next";
import { Accordion, OverlayTrigger, Popover } from "react-bootstrap";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import clsx from "clsx";

import { continentCodes as defaultContinentCodes } from "../../../constants/datasets/continents";
import { useResultsPerContinent } from "../../../hooks/game/useResultsPerContinent";
import { colorsState } from "../../../state/results/colors";
import { ContinentCounter } from "../ended/ContinentCounter";
import { activeCodeState } from "../../../state/game/activeCode";
import { activeContinentState } from "../../../state/results/activeContinent";
import { totalResultsPerContinentState } from "../../../state/results/totalPerContinent";
import { isCityModeState } from "../../../state/game/rules";
import { allResultCodesState } from "../../../state/results/allResultCodes";
import { useIsMulti } from "../../../hooks/shared/useIsMulti";

import "./Results.scss";

export function Results() {
  const allResultCodes = useRecoilValue(allResultCodesState);
  const resultsPerContinent = useResultsPerContinent(allResultCodes);
  const totalResultsPerContinent = useRecoilValue(
    totalResultsPerContinentState
  );
  const activeContinent = useRecoilValue(activeContinentState);
  const [activeKey, setActiveKey] = useState(activeContinent);
  const isMulti = useIsMulti();

  const onToggle = (continentCode) =>
    setActiveKey((activeKey) =>
      activeKey === continentCode ? null : continentCode
    );

  useEffect(() => {
    if (activeContinent) {
      setActiveKey(activeContinent);
    }
  }, [setActiveKey, activeContinent]);

  return (
    <Accordion
      className={clsx("Results", isMulti && "is-multi")}
      activeKey={activeKey}
    >
      {defaultContinentCodes
        .filter((continentCode) => totalResultsPerContinent.get(continentCode))
        .map((continentCode) => (
          <ContinentResults
            key={continentCode}
            continentCode={continentCode}
            results={resultsPerContinent.get(continentCode)}
            totalResults={totalResultsPerContinent.get(continentCode)}
            onToggle={onToggle}
          />
        ))}
    </Accordion>
  );
}

function ContinentResults({ continentCode, results, totalResults, onToggle }) {
  const { t } = useTranslation();
  const setActiveCode = useSetRecoilState(activeCodeState);
  const colors = useRecoilValue(colorsState);
  const isCityMode = useRecoilValue(isCityModeState);

  const onClick = (code) => () => setActiveCode(code);

  return (
    <Accordion.Item eventKey={continentCode} className="bg-dark text-white">
      <Accordion.Header variant="dark" onClick={() => onToggle(continentCode)}>
        {t(`game.results.continents.${continentCode}`)}
        <ContinentCounter results={results} totalResults={totalResults} />
      </Accordion.Header>
      <Accordion.Body>
        <ul className="list-unstyled">
          {results.length ? (
            results.map((result) => (
              <OverlayTrigger
                key={result.iso}
                placement="left"
                overlay={
                  <Popover id={`tooltip-${result.iso}`}>
                    <ResultPopover result={result} />
                  </Popover>
                }
              >
                <li tabIndex={0}>
                  <span className="flag">
                    <span className={`fi fi-${result.flag}`} />
                  </span>
                  <span className="label">
                    <span
                      className="text"
                      onClick={onClick(result.iso)}
                      style={{ color: colors.get(result.iso) }}
                    >
                      {isCityMode ? result.city.names[0] : result.names[0]}
                    </span>
                  </span>
                </li>
              </OverlayTrigger>
            ))
          ) : (
            <small>{t("game.results.empty")}</small>
          )}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function ResultPopover({ result }) {
  return (
    <>
      <Popover.Header as="h3">{result.names[0]}</Popover.Header>
      <Popover.Body className="ResultPopover">
        <div className="Flag">
          <span className={`fi fi-${result.flag}`} />
        </div>
        <ResultDetails data={result} />
        <hr />
        <ResultDetails data={result.city} isCity />
      </Popover.Body>
    </>
  );
}

function ResultDetails({ data, isCity = false }) {
  const { t } = useTranslation();

  const labelKey = isCity
    ? "game.results.popover.capitalCityNames"
    : "game.results.popover.countryNames";

  const namesCount = data.names.length;
  const informalNamesCount = data.informalNames?.length || 0;
  const abbreviationsCount = data.abbreviations?.length || 0;

  const hasInformalNames = !!informalNamesCount;
  const hasAbbreviations = !!abbreviationsCount;

  return (
    <ul className="list-unstyled mx-1 mt-3 mb-0">
      <ResultPopoverNamesList
        label={t(labelKey, { count: namesCount })}
        names={data.names}
      />
      {hasInformalNames && (
        <ResultPopoverNamesList
          label={t("game.results.popover.informalNames", {
            count: informalNamesCount,
          })}
          names={data.informalNames}
        />
      )}
      {hasAbbreviations && (
        <ResultPopoverNamesList
          label={t("game.results.popover.abbreviations", {
            count: abbreviationsCount,
          })}
          names={data.abbreviations}
        />
      )}
    </ul>
  );
}

function ResultPopoverNamesList({ label, names }) {
  return (
    <li>
      <strong>{label}</strong>
      <ul>
        {names.map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </li>
  );
}
