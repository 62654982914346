import { zeroPad } from "react-countdown";

import { TIMER_DURATION } from "../constants/game";

export function getElapsedTime(startedAt, endedAt, isLimited) {
  if (!endedAt) {
    endedAt = Date.now();
  }

  const elapsedTimeMs = endedAt - startedAt;
  return formatElapsedTime(elapsedTimeMs, isLimited)
}

export function formatElapsedTime(elapsedTimeMs, isLimited) {
  const elapsedTime =
    isLimited && elapsedTimeMs > TIMER_DURATION
      ? Math.ceil(TIMER_DURATION / 1000)
      : Math.ceil(elapsedTimeMs / 1000);

  const h = Math.floor(elapsedTime / 3600);
  const m = Math.floor((elapsedTime % 3600) / 60);
  const s = Math.floor((elapsedTime % 3600) % 60);

  return h
    ? `${zeroPad(h)}:${zeroPad(m)}:${zeroPad(s)}`
    : `${zeroPad(m)}:${zeroPad(s)}`;
}
