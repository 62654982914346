import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { allNamesState } from "../../state/results/allNames";

export function useGetNames() {
  const { i18n } = useTranslation();
  const allNames = useRecoilValue(allNamesState);

  return useCallback(
    (countryCode, forCities = false) => {
      const key = forCities ? "cities" : "countries";
      const [names, informalNames, abbreviations] = allNames[key];

      return {
        names: names.get(i18n.language).get(countryCode),
        informalNames: informalNames.get(i18n.language).get(countryCode),
        abbreviations: abbreviations.get(i18n.language).get(countryCode),
      };
    },
    [allNames, i18n.language]
  );
}
