import { useTranslation } from "react-i18next";
import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";

import { useNewRoom } from "../../../hooks/rooms/useNewRoom";
import { NewRoomForm } from "../../../forms/NewRoomForm";
import { RulesForm } from "../../../forms/RulesForm";

export function CreateRoom() {
  const { t } = useTranslation();
  const {
    validated,
    isResetDisabled,
    isLoading,
    error,
    onCreate,
    onReset,
    onHide,
  } = useNewRoom();

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      size="lg"
      fullscreen="lg-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("app.createRoom")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!error && <Alert variant="danger">{error.toString()}</Alert>}
        <Row>
          <Col lg={6}>
            <Card className="mb-3">
              <Card.Header>{t("multi.rooms.new.settings")}</Card.Header>
              <Card.Body>
                <Form id="new-room-form" validated={validated}>
                  <NewRoomForm />
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Header>{t("app.rules")}</Card.Header>
              <Card.Body>
                <RulesForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="danger"
          size="lg"
          disabled={isResetDisabled}
          onClick={onReset}
        >
          {t("multi.rooms.new.buttons.reset")}
        </Button>
        <Button
          variant="primary"
          size="lg"
          type="submit"
          form="new-room-form"
          disabled={isLoading}
          onClick={onCreate}
        >
          {isLoading ? t("app.isLoading") : t("multi.rooms.new.buttons.create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
