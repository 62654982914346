import { useTranslation } from "react-i18next";

import { ErrorPage } from "../../pages/ErrorPage";
import { LoadingPage } from "../../pages/LoadingPage";
import { ProfileLoaded } from "./ProfileLoaded";
import { useProfile } from "../../hooks/profiles/useProfile";

import "./Profile.scss";

export function Profile() {
  const { t } = useTranslation();
  const { isLoading, error, profile } = useProfile();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage message={error.toString()} />;
  }

  if (!profile) {
    return <ErrorPage message={t("app.pageNotFound")} />;
  }

  return <ProfileLoaded className="Profile" profile={profile} />;
}
