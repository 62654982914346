import { Pagination, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";

import { SCORES_PER_PAGE } from "../../constants/leaderboards";
import { ListScoreRow } from "./ListScoreRow";

import "./ListScores.scss";

export function ListScores({ isLoading, scores, pagination }) {
  const { t } = useTranslation();
  const isSmall = useMedia("(width <= 600px)");

  return (
    <>
      <Table striped bordered hover className="ListScores align-middle mt-3">
        <thead>
          <tr>
            <th>{t("leaderboard.rank")}</th>
            {isSmall ? (
              <th>
                {t("leaderboard.player")} & {t("leaderboard.date")}
              </th>
            ) : (
              <>
                <th>{t("leaderboard.player")}</th>
                <th>{t("leaderboard.date")}</th>
              </>
            )}
            <th className="text-end">{t("leaderboard.score")}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading || !scores ? (
            <tr>
              <td colSpan={isSmall ? 3 : 4} className="text-center">
                <Spinner />
              </td>
            </tr>
          ) : scores.length ? (
            scores.map((score, index) => (
              <ListScoreRow
                key={score.id}
                rank={(pagination.page - 1) * SCORES_PER_PAGE + index + 1}
                score={score}
              />
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                {t(`leaderboard.empty`)}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!pagination.isDisabled && (
        <div className="text-center mt-3">
          <Pagination className="d-inline-flex">
            <Pagination.First
              disabled={pagination.isFirstDisabled}
              onClick={pagination.onFirstPage}
            />
            <Pagination.Prev
              disabled={pagination.isPrevDisabled}
              onClick={pagination.onPrevPage}
            />
            <Pagination.Item active>{pagination.page}</Pagination.Item>
            <Pagination.Next
              disabled={pagination.isNextDisabled}
              onClick={pagination.onNextPage}
            />
          </Pagination>
        </div>
      )}
    </>
  );
}
