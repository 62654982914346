import { useTranslation } from "react-i18next";

import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";
import { JoinRoom } from "../components/room/notStarted/JoinRoom";

export function JoinRoomPage() {
  const { t } = useTranslation();
  useDocumentTitle(t("app.joinRoom"));

  return <JoinRoom />;
}
