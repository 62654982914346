import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";

import { profileAchievementsCollection } from "../../collections/achievements";
import { getContinentAchievementName } from "../../helpers/achievements";
import { getAllContinentCodes } from "../../helpers/codes";

export function useProfileContinentsAchievements(mode) {
  const { id } = useParams();

  const isExtendedMode = mode.startsWith("extended");
  const isCityMode = mode.endsWith("Cities");
  const continentCodes = getAllContinentCodes(isExtendedMode);

  const getName = (continentCode) =>
    getContinentAchievementName(isExtendedMode, isCityMode, continentCode);

  const [achievements, isLoading, error] = useCollectionData(
    query(
      profileAchievementsCollection(id),
      where(
        "name",
        "in",
        continentCodes.map((continentCode) => getName(continentCode))
      )
    )
  );

  const foundNames = useMemo(
    () =>
      achievements
        ? new Set(achievements.map((achievement) => achievement.name))
        : new Set(),
    [achievements]
  );

  return { isLoading, error, foundNames, continentCodes, getName };
}
