import { useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isEqual } from "lodash";

import { endedAtState } from "../../state/game/endedAt";
import { foundCodesState } from "../../state/game/foundCodes";
import { rulesState } from "../../state/game/rules";
import { updateRoomPlayer } from "../../collections/players";

export function useRoomStarted(room, user, player) {
  const isMounted = useRef(false);
  const endedAt = useRecoilValue(endedAtState);
  const [foundCodes, setFoundCodes] = useRecoilState(foundCodesState);
  const [rules, setRules] = useRecoilState(rulesState);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    const foundCodesList = Array.from(foundCodes);
    if (!isEqual(foundCodesList, player.foundCodes)) {
      updateRoomPlayer({
        roomId: room.id,
        playerId: user.uid,
        data: { foundCodes: Array.from(foundCodes) },
      }).catch(console.error);
    }
  }, [foundCodes, player.foundCodes, room.id, user.uid]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (endedAt && !player.endedAt) {
      updateRoomPlayer({
        roomId: room.id,
        playerId: user.uid,
        data: { endedAt },
      }).catch(console.error);
    }
  }, [endedAt, player?.endedAt, room.id, user.uid]);

  useEffect(() => {
    if (!isEqual(rules, room.rules)) {
      setRules(room.rules);
    }
  }, [room.rules, rules, setRules]);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    setFoundCodes(new Set(player.foundCodes));
    isMounted.current = true;
  }, [player.foundCodes, setFoundCodes]);
}
