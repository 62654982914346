import { GameInput } from "./GameInput";

import { useGameStarted } from "../../../hooks/game/useGameStarted";
import { GameProgress } from "./GameProgress";
import Timer from "./Timer";
import { ResponsiveResults } from "../shared/ResponsiveResults";

export function StartedGame() {
  useGameStarted();

  return (
    <div className="StartedGame">
      <div className="AppContentTop">
        <GameInput />
        <GameProgress />
      </div>
      <div className="AppContentBottomLeft">
        <Timer />
      </div>
      <ResponsiveResults />
    </div>
  );
}
