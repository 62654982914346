import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";

import { countriesLayerUrl } from "../../../constants/globe";
import { qualityState } from "../../../state/game/settings";
import { countriesQueryState } from "../../../state/layers/countries/query";
import { sleep } from "../../../helpers/sleep";
import { countriesRendererState } from "../../../state/layers/countries/renderer";

export function useCountriesLayer(refs) {
  const quality = useRecoilValue(qualityState);
  const countriesQuery = useRecoilValue(countriesQueryState);
  const countriesRenderer = useRecoilValue(countriesRendererState);
  const [isCountriesLayerReady, setCountriesLayerReady] = useState(false);

  useEffect(() => {
    if (refs.current.countriesLayer) {
      refs.current.map.remove(refs.current.countriesLayer);
      refs.current.countriesLayer.destroy();
      setCountriesLayerReady(false);
    }

    refs.current.countriesLayer = new GeoJSONLayer({
      url: countriesLayerUrl(quality),
      visible: false,
    });

    refs.current.countriesLayer.on("layerview-create", () => {
      setCountriesLayerReady(true);
    });

    refs.current.map.add(refs.current.countriesLayer);
  }, [quality, refs]);

  useEffect(() => {
    refs.current.countriesLayer.definitionExpression = countriesQuery;
  }, [countriesQuery, refs]);

  useEffect(() => {
    sleep(1).then(() => {
      refs.current.countriesLayer.renderer = countriesRenderer;
      refs.current.countriesLayer.visible = isCountriesLayerReady;
    });
  }, [countriesRenderer, isCountriesLayerReady, refs]);

  return { isCountriesLayerReady };
}
