import { selector } from "recoil";

import {
  countryAbbreviationsByCodeByLanguage,
  countryInformalNamesByCodeByLanguage,
  countryNamesByCodeByLanguage,
} from "../../constants/datasets/countries";
import {
  cityAbbreviationsByCodeByLanguage,
  cityInformalNamesByCodeByLanguage,
  cityNamesByCodeByLanguage,
} from "../../constants/datasets/cities";

export const allNamesState = selector({
  key: "allNamesState",
  get: ({ get }) => {
    return {
      cities: [
        cityNamesByCodeByLanguage,
        cityInformalNamesByCodeByLanguage,
        cityAbbreviationsByCodeByLanguage,
      ],
      countries: [
        countryNamesByCodeByLanguage,
        countryInformalNamesByCodeByLanguage,
        countryAbbreviationsByCodeByLanguage,
      ],
    };
  },
});
