import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { GameStatus } from "../../constants/game";
import { StartedGame } from "./started/StartedGame";
import { NotStartedGame } from "./notStarted/NotStartedGame";
import { EndedGame } from "./ended/EndedGame";
import { statusState } from "../../state/game/status";
import { ErrorPage } from "../../pages/ErrorPage";

import "./Game.scss";

export function Game({ endedGameActions = null }) {
  const gameStatus = useRecoilValue(statusState);
  const { t } = useTranslation();

  switch (gameStatus) {
    case GameStatus.notStarted:
      return <NotStartedGame />;
    case GameStatus.started:
      return <StartedGame />;
    case GameStatus.ended:
      return <EndedGame actions={endedGameActions} />;
    default:
      return <ErrorPage message={t("game.invalidStatus")} />;
  }
}
