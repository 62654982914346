import { useRecoilState } from "recoil";

import {
  newRoomIsPrivateState,
  newRoomNameState,
} from "../../state/forms/newRoom";

export function useNewRoomForm() {
  const [name, setName] = useRecoilState(newRoomNameState);
  const [isPrivate, setIsPrivate] = useRecoilState(newRoomIsPrivateState);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onIsPrivateChange = (event) => {
    setIsPrivate(event.target.checked);
  };

  return {
    name: name || "",
    isPrivate,
    onNameChange,
    onIsPrivateChange,
  };
}
