import { useEffect, useMemo, useState } from "react";
import { getCountFromServer } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";

import { profileResultsCollection } from "../../collections/results";
import { profileAchievementsCollection } from "../../collections/achievements";
import {
  allCountriesPerCode,
  countryNamesByCodeByLanguage,
} from "../../constants/datasets/countries";

export function useProfileHeader(userInfo) {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const [totalGames, setTotalGames] = useState(-1);
  const [totalAchievements, setTotalAchievements] = useState(-1);
  const isSmall = useMedia("(width <= 600px)");

  useEffect(() => {
    getCountFromServer(profileResultsCollection(id)).then((snapshot) => {
      setTotalGames(snapshot.data().count);
    });
  }, [id]);

  useEffect(() => {
    getCountFromServer(profileAchievementsCollection(id)).then((snapshot) => {
      setTotalAchievements(snapshot.data().count);
    });
  }, [id]);

  const countryData = useMemo(() => {
    if (!userInfo.country) {
      return {};
    }

    const country = allCountriesPerCode.get(userInfo.country);
    const flagCode = country?.flag || "xx";

    const countryName = countryNamesByCodeByLanguage
      .get(i18n.language)
      .get(userInfo.country);

    return { countryName, flagCode };
  }, [i18n.language, userInfo.country]);

  return { isSmall, userInfo, totalGames, totalAchievements, ...countryData };
}
