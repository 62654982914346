import { useEffect, useState } from "react";

import { onUserInfo } from "../../collections/users";

export function useUserInfo(userId, url = null) {
  const [state, setState] = useState({});

  useEffect(() => onUserInfo(userId, url, setState), [url, userId]);

  return state;
}
