import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { normalizeString } from "../../helpers/string";
import { rulesState } from "../../state/game/rules";
import { allCodesState } from "../../state/results/allCodes";
import { resultNamesState } from "../../state/results/resultNames";
import { ValidInputs } from "../../helpers/input";

export function useValidInputs() {
  const { i18n } = useTranslation();
  const rules = useRecoilValue(rulesState);
  const resultNames = useRecoilValue(resultNamesState);
  const allCodes = useRecoilValue(allCodesState);

  return useMemo(() => {
    const [names, informalNames, abbreviations] = resultNames;
    const validInputs = new ValidInputs();

    const registerValidInputs = (namesByCode) => {
      for (const [code, names] of namesByCode) {
        if (!allCodes.has(code)) {
          continue;
        }

        for (const name of names) {
          validInputs.register(normalizeString(name), code);
        }
      }
    };

    registerValidInputs(names.get(i18n.language));

    if (rules.allowInformalNames) {
      registerValidInputs(informalNames.get(i18n.language));
    }

    if (rules.allowAbbreviations) {
      registerValidInputs(abbreviations.get(i18n.language));
    }

    return validInputs;
  }, [
    i18n.language,
    rules.allowInformalNames,
    rules.allowAbbreviations,
    resultNames,
    allCodes,
  ]);
}
