import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";

import { unsavedAchievementsState } from "../../state/users/achievements";
import { useWriteAchievements } from "./useWriteAchievements";
import { auth } from "../../firebase";

export function useAutosaveAchievements() {
  const [user] = useAuthState(auth);
  const [unsavedAchievements, setUnsavedAchievements] = useRecoilState(
    unsavedAchievementsState
  );
  const writeAchievement = useWriteAchievements();

  useEffect(() => {
    if (!user?.uid || !unsavedAchievements.length) {
      return;
    }

    async function run() {
      for (const partialData of unsavedAchievements) {
        await writeAchievement(partialData);
      }

      setUnsavedAchievements([]);
    }

    run().catch(console.error);
  }, [
    setUnsavedAchievements,
    unsavedAchievements,
    user?.displayName,
    user?.uid,
    writeAchievement,
  ]);
}
