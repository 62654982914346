import { selector } from "recoil";

import { visibleCountriesState } from "./visible";
import { CODE_FIELD, OBJECT_ID_FIELD } from "../../../constants/globe";

export const countriesQueryState = selector({
  key: "countriesQueryState",
  get: ({ get }) => {
    const visibleCountries = get(visibleCountriesState);

    if (!visibleCountries.length) {
      return `${OBJECT_ID_FIELD} < 0`;
    }

    const formattedCodes = visibleCountries.map((code) => `'${code}'`);
    return `${CODE_FIELD} IN (${formattedCodes.join(", ")})`;
  },
});
