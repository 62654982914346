import { atom } from "recoil";

import { persistAtom } from "../../helpers/state";
import { Quality } from "../../constants/quality";

export const autoZoomState = atom({
  key: "autoZoomState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const qualityState = atom({
  key: "qualityState",
  default: Quality.medium,
  effects_UNSTABLE: [persistAtom],
});
