export const ModeRule = {
  mainCountries: "mainCountries",
  extendedTerritories: "extendedTerritories",
  capitalCities: "capitalCities",
  extendedCities: "extendedCities",
};

export const DifficultyRule = {
  visibleBorders: "visibleBorders",
  hiddenBorders: "hiddenBorders",
  hiddenTerritories: "hiddenTerritories",
};

export const defaultRules = {
  mode: ModeRule.mainCountries,
  difficulty: DifficultyRule.visibleBorders,
  timer: true,
  allowTypos: true,
  allowInformalNames: true,
  allowAbbreviations: true,
};
