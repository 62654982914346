import { useTranslation } from "react-i18next";
import { Button, Card } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";

import { useTimer } from "../../../hooks/game/useTimer";

export default function Timer() {
  const { t } = useTranslation();
  const { hasCountdown, date, onComplete } = useTimer();

  return (
    <>
      {hasCountdown && (
        <Card className="text-center" style={{ width: "10rem" }}>
          <Card.Body>
            <small>
              {t("game.timer.timeLeft")}{" "}
              <Countdown
                date={date}
                onComplete={onComplete}
                renderer={({ minutes, seconds }) =>
                  `${zeroPad(minutes)}:${zeroPad(seconds)}`
                }
              />
            </small>
          </Card.Body>
        </Card>
      )}
      <Button
        variant="danger"
        size="lg"
        className="w-100 mt-3"
        onClick={onComplete}
      >
        {t("game.timer.giveUp")}
      </Button>
    </>
  );
}
