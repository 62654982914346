import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";
import { AuthModal } from "../components/auth/AuthModal";
import { auth } from "../firebase";
import { SuccessPage } from "./SuccessPage";

export function AuthPage() {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  useDocumentTitle(t("pages.auth.title"));

  if (user?.email) {
    return (
      <SuccessPage
        title={t("pages.auth.title")}
        message={t(`pages.auth.success`)}
      />
    );
  }

  return <AuthModal />;
}
