import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { normalizeString } from "../../helpers/string";
import { useValidInputs } from "./useValidInputs";
import { rulesState } from "../../state/game/rules";
import { useOnStop } from "./useOnStop";
import { foundCodesState } from "../../state/game/foundCodes";
import { inputState } from "../../state/game/input";
import { activeCodeState } from "../../state/game/activeCode";
import { allCodesState } from "../../state/results/allCodes";
import { useContinentsAchievements } from "../achievements/useContinentsAchievements";
import { useCompletionAchievements } from "../achievements/useCompletionAchievements";
import { useNotifications } from "../notifications/useNotifications";
import { SUGGESTION_MIN_LENGTH } from "../../constants/game";

export function useGameInput() {
  const { t } = useTranslation();
  const [value, setValue] = useRecoilState(inputState);
  const [foundCodes, setFoundCodes] = useRecoilState(foundCodesState);
  const rules = useRecoilValue(rulesState);
  const allCodes = useRecoilValue(allCodesState);
  const setActiveCode = useSetRecoilState(activeCodeState);
  const validInputs = useValidInputs();
  const { onStop } = useOnStop();
  const [suggestion, setSuggestion] = useState(null);
  const [isShaking, setIsShaking] = useState(false);
  const { notify } = useNotifications();
  const checkContinentsAchievements = useContinentsAchievements();
  const checkCompletionAchievements = useCompletionAchievements();

  const onMatch = useCallback(
    (matches) => {
      const isDone = foundCodes.size >= allCodes.size - matches.length;

      setFoundCodes((codes) => new Set([...codes, ...matches]));
      setActiveCode(matches[0]);
      checkContinentsAchievements(matches);

      if (isDone) {
        onStop(matches);
        checkCompletionAchievements();
      } else {
        setValue("");
        setSuggestion(null);
      }
    },
    [
      allCodes.size,
      checkCompletionAchievements,
      checkContinentsAchievements,
      foundCodes.size,
      onStop,
      setActiveCode,
      setFoundCodes,
      setValue,
    ]
  );

  const onAlreadyFound = useCallback(
    (normalizedValue) => {
      if (normalizedValue.length < SUGGESTION_MIN_LENGTH) {
        return;
      }

      setIsShaking(true);

      notify({
        title: t("notifications.alreadyFound.title"),
        message: t("notifications.alreadyFound.message"),
        autoHideAfterSeconds: 3,
        variant: "warning",
      });

      setTimeout(() => {
        setIsShaking(false);
      }, 1000);
    },
    [notify, t]
  );

  const onNoMatch = useCallback(
    (normalizedValue) => {
      if (!rules.allowTypos || normalizedValue.length < SUGGESTION_MIN_LENGTH) {
        return;
      }

      const newSuggestion = validInputs.getSuggestion(
        normalizedValue,
        foundCodes
      );
      setSuggestion(newSuggestion);

      return newSuggestion;
    },
    [foundCodes, rules.allowTypos, validInputs]
  );

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      const normalizedValue = normalizeString(value);
      const { matches, futureMatches } = validInputs.query(normalizedValue);

      if (matches.some((match) => !foundCodes.has(match))) {
        onMatch(matches);
      } else {
        setValue(value);
        const suggestion = onNoMatch(normalizedValue);

        if (
          !suggestion &&
          matches.length &&
          futureMatches.every((futureMatch) => foundCodes.has(futureMatch))
        ) {
          onAlreadyFound(normalizedValue);
        }
      }
    },
    [foundCodes, onAlreadyFound, onMatch, onNoMatch, setValue, validInputs]
  );

  const onSuggestionClick = useCallback(() => {
    const { matches } = validInputs.query(suggestion);
    onMatch(matches);
  }, [onMatch, suggestion, validInputs]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" && suggestion) {
        onSuggestionClick();
      }
    },
    [suggestion, onSuggestionClick]
  );

  const onForbidden = useCallback(
    (event) => {
      event.preventDefault();

      notify({
        title: t("notifications.pasteForbidden.title"),
        message: t("notifications.pasteForbidden.message"),
        autoHideAfterSeconds: 15,
        variant: "danger",
      });

      return false;
    },
    [notify, t]
  );

  return {
    value,
    suggestion,
    isShaking,
    onChange,
    onKeyDown,
    onSuggestionClick,
    onForbidden,
  };
}
