import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  allCountriesPerCode,
  countryNamesByCodeByLanguage,
} from "../../constants/datasets/countries";
import { cityNamesByCodeByLanguage } from "../../constants/datasets/cities";
import { LEADERBOARD_ID_FORMAT } from "../../constants/leaderboards";
import { getLeaderboardId } from "../../collections/leaderboards";
import { profileResultsCollection } from "../../collections/results";
import { rulesState } from "../../state/game/rules";
import { getAllCodes } from "../../helpers/codes";

export function useStatistics(profile) {
  const { i18n } = useTranslation();
  const rules = useRecoilValue(rulesState);
  const [filters, setFilters] = useState(rules);
  const [grouped, setGrouped] = useState(false);
  const [leaderboardId, setLeaderboardId] = useState(getLeaderboardId());
  const isCityMode = useMemo(
    () => filters.mode.endsWith("Cities"),
    [filters.mode]
  );
  const isExtendedMode = useMemo(
    () => filters.mode.startsWith("extended"),
    [filters.mode]
  );

  const createdAtMin = useMemo(
    () => dayjs.utc(leaderboardId, LEADERBOARD_ID_FORMAT).valueOf(),
    [leaderboardId]
  );

  const createdAtMax = useMemo(
    () =>
      dayjs.utc(leaderboardId, LEADERBOARD_ID_FORMAT).endOf("month").valueOf(),
    [leaderboardId]
  );

  const [results, isLoading, error] = useCollectionData(
    query(
      profileResultsCollection(profile.id),
      where("rules", "==", filters),
      where("createdAt", ">=", createdAtMin),
      where("createdAt", "<=", createdAtMax)
    )
  );

  const onSelectChange = useCallback(
    (ruleName) => (event) => {
      setFilters((filters) => ({ ...filters, [ruleName]: event.target.value }));
    },
    [setFilters]
  );

  const onSwitchChange = useCallback(
    (ruleName) => (event) => {
      setFilters((filters) => ({
        ...filters,
        [ruleName]: event.target.checked,
      }));
    },
    [setFilters]
  );

  const onGroupedChange = useCallback(
    (event) => setGrouped(event.target.checked),
    []
  );

  const onLeaderboardIdChange = useCallback((event) => {
    setLeaderboardId(event.target.value);
  }, []);

  const parsedResults = useMemo(
    () =>
      results
        ? results.map((result) => ({
            ...result,
            foundCodes: new Set(result.foundCodes),
          }))
        : [],
    [results]
  );

  const items = useMemo(
    () =>
      parsedResults.length
        ? Array.from(getAllCodes(filters.mode))
            .map((code) => {
              const percentage =
                parsedResults.reduce(
                  (total, result) =>
                    result.foundCodes.has(code) ? total + 1 : total,
                  0
                ) / parsedResults.length;

              return {
                code,
                continentCode: allCountriesPerCode.get(code).continent,
                flag: allCountriesPerCode.get(code).flag,
                label: isCityMode
                  ? cityNamesByCodeByLanguage.get(i18n.language).get(code)[0]
                  : countryNamesByCodeByLanguage
                      .get(i18n.language)
                      .get(code)[0],
                percentage,
                color: `hsl(${percentage * 120}, 100%, 50%)`,
              };
            })
            .sort((a, b) =>
              a.percentage === b.percentage
                ? a.label.localeCompare(b.label)
                : a.percentage - b.percentage
            )
        : [],
    [filters.mode, i18n.language, isCityMode, parsedResults]
  );

  return {
    isLoading,
    error,
    filters,
    leaderboardId,
    grouped,
    totalResults: parsedResults.length,
    items,
    isExtendedMode,
    onSelectChange,
    onSwitchChange,
    onGroupedChange,
    onLeaderboardIdChange,
  };
}
