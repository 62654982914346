import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card, Dropdown } from "react-bootstrap";

import { HomeAccountDropdownMenu } from "./HomeAccountDropdownMenu";
import { useHome } from "../../hooks/home/useHome";
import { Title } from "../shared/Title";

export function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useHome();

  return (
    <div className="Home">
      <div className="AppContentTop">
        <Card bg="light" text="dark">
          <Card.Header>{t("pages.home.title")}</Card.Header>
          <Card.Body>
            <Card.Text>{t("pages.home.text")}</Card.Text>
            <div className="d-flex gap-3 flex-wrap">
              <Button
                size="lg"
                className="flex-grow-1"
                onClick={() => navigate("/single")}
              >
                {t("app.singlePlayer")}
              </Button>
              <Dropdown className="flex-grow-1" align="end">
                <Dropdown.Toggle
                  size="lg"
                  className="w-100"
                  id="multiplayer-dropdown"
                >
                  {t("app.multiPlayer")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/multi/rooms")}>
                    {t("app.joinRoom")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/multi/rooms/new")}>
                    {t("app.createRoom")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/multi/leaderboard")}>
                    {t("app.leaderboard")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="flex-grow-1" align="end">
                <Dropdown.Toggle
                  size="lg"
                  className="w-100"
                  id="account-dropdown"
                  variant="dark"
                >
                  {t("pages.home.buttons.account")}
                </Dropdown.Toggle>
                <HomeAccountDropdownMenu />
              </Dropdown>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="AppContentBottomLeft">
        <div className="text-center">
          <div
            className="px-4 py-2 bg-light d-inline-block"
            style={{ borderRadius: "1rem" }}
          >
            <Title className="text-bg-light" />
          </div>
        </div>
      </div>
    </div>
  );
}
