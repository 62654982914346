import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";

import { converter } from "../helpers/collections";
import { getLeaderboardId, leaderboardsCollection } from "./leaderboards";

export const scoresCollection = (leaderboardId) =>
  collection(leaderboardsCollection, leaderboardId, "scores").withConverter(
    converter
  );

export async function createScore({
  value,
  elapsedTime,
  rules,
  createdBy,
  createdByUsername,
  createdAt,
}) {
  const leaderboardId = getLeaderboardId(createdAt);
  const scores = await getScores({ leaderboardId, rules, createdBy });
  const isBest = scores.every((score) =>
    value === score.value
      ? elapsedTime < score.elapsedTime
      : value > score.value
  );

  if (!isBest) {
    return false;
  }

  await addDoc(scoresCollection(leaderboardId), {
    value,
    elapsedTime,
    rules,
    createdBy,
    createdByUsername,
    createdAt,
  });

  scores.forEach((score) => {
    deleteScore(leaderboardId, score.id);
  });

  return true;
}

async function getScores({ leaderboardId, rules, createdBy }) {
  const snapshot = await getDocs(
    query(
      scoresCollection(leaderboardId),
      where("rules", "==", rules),
      where("createdBy", "==", createdBy)
    )
  );

  return snapshot.docs.map((doc) => doc.data());
}

async function deleteScore(leaderboardId, id) {
  const ref = doc(scoresCollection(leaderboardId), id);
  await deleteDoc(ref);
}
