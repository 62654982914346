import { atom } from "recoil";

export const authFormState = atom({
  key: "authFormState",
  default: {
    username: "",
    country: "",
    isAnon: false,
    email: "",
    password: "",
  },
});
