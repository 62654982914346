export const GameStatus = {
  notStarted: "notStarted",
  started: "started",
  ended: "ended",
};

export const TIMER_DURATION = 15 * 60 * 1000; // 15 minutes (in ms)

export const SUGGESTION_MIN_LENGTH = 5;
export const SUGGESTION_MIN_SIMILARITY = 0.75;
export const SUGGESTION_MAX_RELATIVE = 0.25;
