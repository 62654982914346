import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useIsMulti() {
  const location = useLocation();

  return useMemo(
    () => location.pathname.startsWith("/multi"),
    [location.pathname]
  );
}
