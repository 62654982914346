import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { roomPlayersCollection } from "../../collections/players";
import { onUserInfo } from "../../collections/users";

export function useRoomPlayers(room, user) {
  const location = useLocation();
  const [playersMap, setPlayersMap] = useState(new Map());
  const [rawPlayers, arePlayersLoading, error] = useCollectionData(
    roomPlayersCollection(room.id)
  );

  useEffect(() => {
    rawPlayers?.forEach((rawPlayer) => {
      setPlayersMap((playersMap) => {
        const newMap = new Map(playersMap);

        newMap.set(rawPlayer.id, {
          ...rawPlayer,
          isAdmin: rawPlayer.createdBy === room.createdBy,
          score: rawPlayer.foundCodes?.length || 0,
          userInfo: playersMap.get(rawPlayer.id)?.userInfo || {},
        });

        return newMap;
      });
    });

    const unsubscribes = rawPlayers?.map((rawPlayer) =>
      onUserInfo(rawPlayer.id, location.pathname, (userInfo) => {
        setPlayersMap((playersMap) => {
          const newMap = new Map(playersMap);

          if (newMap.has(rawPlayer.id)) {
            newMap.get(rawPlayer.id).userInfo = userInfo;
          }

          return newMap;
        });
      })
    );

    return () => {
      unsubscribes?.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [location.pathname, rawPlayers, room.createdBy]);

  const players = useMemo(() => Array.from(playersMap.values()), [playersMap]);

  const player = useMemo(
    () => players.find((player) => player.id === user.uid),
    [players, user.uid]
  );

  return {
    players,
    player,
    arePlayersLoading:
      arePlayersLoading || rawPlayers?.length !== players.length,
    error,
  };
}
