import { useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../firebase";
import { updateRoomPlayer } from "../../collections/players";

export function useRoomLobbyPlayer(room) {
  const [user] = useAuthState(auth);

  const onReady = useCallback(
    (event) => {
      updateRoomPlayer({
        roomId: room.id,
        playerId: user?.uid,
        data: { isReady: event.target.checked },
      }).catch(console.error);
    },
    [room.id, user?.uid]
  );

  return { onReady };
}
