import { useCallback } from "react";
import Point from "@arcgis/core/geometry/Point";

export function useGoTo(refs) {
  return useCallback(
    ([longitude, latitude], zoom = null) => {
      const target = {
        target: new Point({ longitude, latitude }),
        rotation: 0,
        tilt: 0,
      };

      if (zoom) {
        target.zoom = zoom;
      }

      const options = { maxDuration: 250 };

      refs.current.sceneView.goTo(target, options).catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      });
    },
    [refs]
  );
}
