import { Button, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { useResponsiveResults } from "../../../hooks/game/useResponsiveResults";
import { Results } from "./Results";

import "./ResponsiveResults.scss";

export function ResponsiveResults() {
  const { t } = useTranslation();
  const state = useResponsiveResults();

  return (
    <div className="ResponsiveResults">
      {state.isSmall ? (
        <>
          <Button
            variant="light"
            tabIndex={0}
            className="OpenResultsButton"
            size="lg"
            onClick={state.onOpenResults}
          >
            <FontAwesomeIcon icon={faAnglesLeft} fixedWidth />
          </Button>
          <Offcanvas
            show={state.areResultsOpen}
            onHide={state.onCloseResults}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{t("app.results")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Results />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        <div className="AppContentRight">
          <Results />
        </div>
      )}
    </div>
  );
}
