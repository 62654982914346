import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { continentCodes } from "../../constants/datasets/continents";
import { allCountriesPerCode } from "../../constants/datasets/countries";
import { isCityModeState } from "../../state/game/rules";
import { useGetNames } from "./useGetNames";

export function useResultsPerContinent(codes) {
  const isCityMode = useRecoilValue(isCityModeState);
  const getNames = useGetNames();

  return useMemo(
    () =>
      new Map(
        continentCodes.map((continentCode) => {
          let results = codes
            .map((countryCode) => allCountriesPerCode.get(countryCode))
            .filter((country) => country.continent === continentCode)
            .map((country) => ({
              ...country,
              ...getNames(country.iso),
              city: getNames(country.iso, true),
            }));

          const getName = (result) =>
            isCityMode ? result.city.names[0] : result.names[0];

          results.sort((a, b) => getName(a).localeCompare(getName(b)));

          return [continentCode, results];
        })
      ),
    [codes, getNames, isCityMode]
  );
}
