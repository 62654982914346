import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { rulesState } from "../../state/game/rules";
import { TIMER_DURATION } from "../../constants/game";
import { useOnStop } from "./useOnStop";
import { startedAtState } from "../../state/game/startedAt";

export function useTimer() {
  const rules = useRecoilValue(rulesState);
  const startedAt = useRecoilValue(startedAtState);
  const { onStop } = useOnStop();

  const hasCountdown = rules.timer;
  const date = startedAt + TIMER_DURATION;

  const onComplete = useCallback(() => {
    onStop();
  }, [onStop]);

  return { hasCountdown, date, onComplete };
}
