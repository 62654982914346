import { useTranslation } from "react-i18next";

import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";
import { Leaderboard } from "../components/leaderboard/Leaderboard";

export function LeaderboardPage() {
  const { t } = useTranslation();
  useDocumentTitle(t("app.leaderboard"));

  return <Leaderboard />;
}
