import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { isEqual } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { updateRoom } from "../../collections/rooms";
import { rulesState } from "../../state/game/rules";
import { TIMER_DURATION } from "../../constants/game";
import { createRoomPlayer } from "../../collections/players";

export function useRoomLobby(room, user, playersValues) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [rules, setRules] = useRecoilState(rulesState);
  const [error, setError] = useState(null);
  const [isStarting, setIsStarting] = useState(false);

  playersValues.players?.sort((a, b) =>
    a.username?.toLowerCase() === b.username?.toLowerCase()
      ? a.createdAt - b.createdAt
      : a.username?.localeCompare(b.username)
  );

  const isAdmin = user.uid === room.createdBy;
  const admin = playersValues.players.find((player) => player.isAdmin);
  const arePlayersReady = playersValues.players
    .filter((player) => player.userInfo.isOnline)
    .every((player) => player.isReady);
  const startDisabled = !isAdmin || !arePlayersReady || isStarting;

  const onStart = useCallback(async () => {
    try {
      setError(null);
      setIsStarting(true);
      const startedAt = Date.now();
      const endedAt = rules.timer ? startedAt + TIMER_DURATION : false;
      await updateRoom({ roomId: room.id, data: { startedAt, endedAt } });
    } catch (error) {
      setError(null);
    } finally {
      setIsStarting(false);
    }
  }, [room.id, rules.timer]);

  const onLeave = useCallback(() => {
    const origin = location.state?.origin || "/";
    navigate(origin);
  }, [location.state?.origin, navigate]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (isAdmin && !isEqual(rules, room.rules)) {
      updateRoom({ roomId: room.id, data: { rules } }).catch(setError);
    }
  }, [isAdmin, room.id, room.rules, rules]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (!isAdmin && !isEqual(rules, room.rules)) {
      setRules(room.rules);
    }
  }, [isAdmin, room.rules, rules, setRules]);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    setRules(room.rules);

    createRoomPlayer({
      roomId: room.id,
      playerId: user.uid,
      username: user.displayName,
      isReady: user.uid === room.createdBy,
    })
      .then(() => {
        isMounted.current = true;
      })
      .catch(setError);
  }, [
    room.createdBy,
    room.id,
    room.rules,
    setRules,
    user.displayName,
    user.uid,
  ]);

  return {
    error: error || playersValues.error,
    arePlayersReady,
    admin,
    startDisabled,
    onLeave,
    onStart,
  };
}
