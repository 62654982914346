import { useRef } from "react";

import { useMap } from "./useMap";
import { useSceneView } from "./useSceneView";
import { useCitiesLayer } from "./layers/useCitiesLayer";
import { useCountriesLayer } from "./layers/useCountriesLayer";
import { useAntarcticLayer } from "./layers/useAntarcticLayer";
import { useLayersReady } from "./layers/useLayersReady";

export function useGlobe() {
  const containerRef = useRef();
  const refs = useRef({
    map: null,
    sceneView: null,
    antarcticaLayer: null,
    countriesLayer: null,
    citiesLayer: null,
  });

  useMap(refs);
  useSceneView(containerRef, refs);

  const { isCitiesLayerReady } = useCitiesLayer(refs);
  const { isCountriesLayerReady } = useCountriesLayer(refs);
  const { isAntarcticaLayerReady } = useAntarcticLayer(refs);

  useLayersReady({
    isCitiesLayerReady,
    isCountriesLayerReady,
    isAntarcticaLayerReady,
  });

  return containerRef;
}
