import { useTranslation } from "react-i18next";

import { RoomStarted } from "../started/RoomStarted";
import { RoomEnded } from "../ended/RoomEnded";
import { ErrorPage } from "../../../pages/ErrorPage";
import { useRoomStartedOrEnded } from "../../../hooks/rooms/useRoomStartedOrEnded";
import { ResponsiveRoomScores } from "./ResponsiveRoomScores";
import { LoadingPage } from "../../../pages/LoadingPage";

export function RoomStartedOrEnded({ room, user, playersValues }) {
  const { t } = useTranslation();
  useRoomStartedOrEnded(room, user, playersValues.players);

  if (playersValues.arePlayersLoading) {
    return <LoadingPage />;
  }

  if (playersValues.error) {
    return <ErrorPage message={playersValues.error.toString()} />;
  }

  if (!playersValues.player) {
    return <ErrorPage message={t("multi.rooms.lobby.alreadyStarted")} />;
  }

  return (
    <>
      {playersValues.player.endedAt ? (
        <RoomEnded
          room={room}
          user={user}
          players={playersValues.players}
          player={playersValues.player}
        />
      ) : (
        <RoomStarted room={room} user={user} player={playersValues.player} />
      )}
      {!playersValues.arePlayersLoading && (
        <ResponsiveRoomScores room={room} players={playersValues.players} />
      )}
    </>
  );
}
