import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../firebase";
import { ErrorPage } from "../pages/ErrorPage";
import { LoadingPage } from "../pages/LoadingPage";

export function AuthLayout() {
  const location = useLocation();
  const [user, isLoading, error] = useAuthState(auth);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage message={error.toString()} />;
  }

  if (!user?.uid) {
    return <Navigate to="/auth" state={{ origin: location.pathname }} />;
  }

  return <Outlet />;
}
