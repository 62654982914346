import { collection } from "firebase/firestore";
import dayjs from "dayjs";

import { LEADERBOARD_ID_FORMAT } from "../constants/leaderboards";
import { converter } from "../helpers/collections";
import { db } from "../firebase";

export const leaderboardsCollection = collection(
  db,
  "leaderboards"
).withConverter(converter);

export function getLeaderboardId(date = Date.now()) {
  return dayjs.utc(date).format(LEADERBOARD_ID_FORMAT);
}
