import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { isEqual } from "lodash";

import { rulesState } from "../../state/game/rules";
import { defaultRules } from "../../constants/rules";
import { auth } from "../../firebase";
import {
  newRoomIsPrivateState,
  newRoomNameState,
} from "../../state/forms/newRoom";
import { createRoom } from "../../collections/rooms";

export function useNewRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const rules = useRecoilValue(rulesState);
  const name = useRecoilValue(newRoomNameState);
  const isPrivate = useRecoilValue(newRoomIsPrivateState);
  const resetName = useResetRecoilState(newRoomNameState);
  const resetIsPrivate = useResetRecoilState(newRoomIsPrivateState);
  const [validated, setValidated] = useState(false);
  const onReset = useResetRecoilState(rulesState);
  const [user] = useAuthState(auth);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isResetDisabled = useMemo(() => isEqual(rules, defaultRules), [rules]);

  const onCreate = useCallback(
    async (event) => {
      event.preventDefault();
      setValidated(true);

      if (!name) {
        return;
      }

      try {
        setLoading(true);
        const room = await createRoom({
          name,
          isPrivate,
          rules,
          createdBy: user.uid,
          createdByUsername: user.displayName,
        });
        navigate(`/multi/rooms/${room.id}`, { replace: true });
        resetName();
        resetIsPrivate();
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [
      name,
      isPrivate,
      rules,
      user.uid,
      user.displayName,
      navigate,
      resetName,
      resetIsPrivate,
    ]
  );

  const onHide = useCallback(() => {
    const origin = location.state?.origin || "/";
    navigate(origin);
  }, [location.state?.origin, navigate]);

  return {
    validated,
    isResetDisabled,
    isLoading,
    error,
    onCreate,
    onReset,
    onHide,
  };
}
