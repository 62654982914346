import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";

import {
  areResultsSavedState,
  areResultsSavingState,
  unsavedResultsState,
} from "../../state/users/results";
import { useNotifications } from "../notifications/useNotifications";
import { saveResultAndScore } from "../../collections/users";
import { startedAtState } from "../../state/game/startedAt";
import { rulesState } from "../../state/game/rules";
import { auth } from "../../firebase";

export function useSaveResults() {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const rules = useRecoilValue(rulesState);
  const startedAt = useRecoilValue(startedAtState);
  const setIsCreating = useSetRecoilState(areResultsSavingState);
  const setIsCreated = useSetRecoilState(areResultsSavedState);
  const setUnsavedResults = useSetRecoilState(unsavedResultsState);
  const { notify } = useNotifications();

  const onResultsSaved = useCallback(() => {
    notify({
      title: t("notifications.newPersonalBest.title"),
      message: t("notifications.newPersonalBest.message"),
      autoHideAfterSeconds: 30,
    });
  }, [notify, t]);

  const writeResults = useCallback(
    async (partialData) => {
      try {
        setIsCreating(true);
        const isBestScore = await saveResultAndScore({
          ...partialData,
          createdBy: user.uid,
          createdByUsername: user.displayName,
        });
        setIsCreated(true);
        return isBestScore;
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreating(false);
      }
    },
    [setIsCreated, setIsCreating, user?.displayName, user?.uid]
  );

  const saveResults = useCallback(
    async (endedAt, foundCodes) => {
      if (!foundCodes.size) {
        return;
      }

      const partialData = {
        foundCodes: Array.from(foundCodes),
        elapsedTime: endedAt - startedAt,
        rules,
        createdAt: Date.now(),
      };

      if (!user?.uid) {
        setUnsavedResults((results) => [...results, partialData]);
        return;
      }

      const isBestScore = await writeResults(partialData);
      if (isBestScore) {
        onResultsSaved();
      }
    },
    [
      onResultsSaved,
      rules,
      setUnsavedResults,
      startedAt,
      user?.uid,
      writeResults,
    ]
  );

  return { writeResults, saveResults, onResultsSaved };
}
