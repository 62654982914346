import { Badge, Form, ListGroup } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";

import { auth } from "../../../firebase";
import { useRoomLobbyPlayer } from "../../../hooks/rooms/useRoomLobbyPlayer";
import { Username } from "../../user/Username";

export function RoomLobbyPlayer({ room, player }) {
  const { t } = useTranslation();
  const { onReady } = useRoomLobbyPlayer(room);
  const [user] = useAuthState(auth);

  return (
    <ListGroup.Item>
      {player.userInfo.isOnline && (
        <div className="float-end d-inline-block">
          <Form.Check
            id={`player-${player.id}-ready`}
            type="switch"
            reverse
            label={
              player.isReady
                ? t("multi.rooms.lobby.players.ready")
                : t("multi.rooms.lobby.players.notReady")
            }
            autoFocus={user.uid === player.id}
            className={player.isReady ? "text-primary" : "text-danger"}
            disabled={player.id !== user.uid}
            checked={player.isReady}
            onChange={onReady}
          />
        </div>
      )}
      <Username id={player.id} value={player.username} {...player.userInfo} />
      {room.createdBy === player.id && (
        <Badge pill bg="primary" className="ms-1">
          {t("multi.rooms.lobby.players.admin")}
        </Badge>
      )}
    </ListGroup.Item>
  );
}
