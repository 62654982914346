import { useRecoilState } from "recoil";

import { autoZoomState, qualityState } from "../../state/game/settings";

export function useSettingsForm() {
  const [quality, setQuality] = useRecoilState(qualityState);
  const [autoZoom, setAutoZoom] = useRecoilState(autoZoomState);

  const onQualityChange = (event) => {
    setQuality(event.target.value);
  };

  const onAutoZoomChange = (event) => {
    setAutoZoom(event.target.checked);
  };

  return {
    quality,
    autoZoom,
    onQualityChange,
    onAutoZoomChange,
  };
}
