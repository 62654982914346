import { selector } from "recoil";

import { visibleCitiesState } from "./visible";
import { CODE_FIELD, OBJECT_ID_FIELD } from "../../../constants/globe";

export const citiesQueryState = selector({
  key: "citiesQueryState",
  get: ({ get }) => {
    const visibleCities = get(visibleCitiesState);

    if (!visibleCities.length) {
      return `${OBJECT_ID_FIELD} < 0`;
    }

    const formattedCodes = visibleCities.map((code) => `'${code}'`);
    return `${CODE_FIELD} IN (${formattedCodes.join(", ")})`;
  },
});
