import { useMemo } from "react";
import { Form } from "react-bootstrap";
import dayjs from "dayjs";

import {
  LEADERBOARD_ID_FORMAT,
  LEADERBOARD_START,
} from "../../constants/leaderboards";
import { getLeaderboardId } from "../../collections/leaderboards";
import { FormattedDate } from "../shared/FormattedDate";

export function LeaderboardDropdown({ value, onChange, className }) {
  const options = useMemo(() => {
    const options = [];
    const end = dayjs.utc();
    let date = dayjs.utc(LEADERBOARD_START, LEADERBOARD_ID_FORMAT);

    while (date <= end) {
      options.push(getLeaderboardId(date));
      date = date.add(1, "month");
    }

    return options;
  }, []);

  return (
    <Form.Select
      size="lg"
      className={className}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          <FormattedDate
            value={option}
            inputFormat={LEADERBOARD_ID_FORMAT}
            outputFormat="MMMM YYYY"
          />
        </option>
      ))}
    </Form.Select>
  );
}
