import { selector } from "recoil";
import Color from "@arcgis/core/Color";
import UniqueValueInfo from "@arcgis/core/renderers/support/UniqueValueInfo";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";

import { isCityModeState, rulesState } from "../../game/rules";
import { statusState } from "../../game/status";
import { colorsState } from "../../results/colors";
import { visibleCountriesState } from "./visible";
import { DifficultyRule } from "../../../constants/rules";
import { GameStatus } from "../../../constants/game";
import { CODE_FIELD } from "../../../constants/globe";
import { themeState } from "../../game/theme";

export const countriesRendererState = selector({
  key: "countriesRendererState",
  get: ({ get }) => {
    const rules = get(rulesState);
    const theme = get(themeState);
    const gameStatus = get(statusState);
    const colors = get(colorsState);
    const visibleCountries = get(visibleCountriesState);
    const isCityMode = get(isCityModeState);

    const isDifficultyEasy = rules.difficulty === DifficultyRule.visibleBorders;
    const hasGameEnded = gameStatus === GameStatus.ended;

    const hasVisibleBorders = isDifficultyEasy || hasGameEnded;

    const createInfo = (value) => {
      const color = Color.fromHex(
        isCityMode ? theme.groundColor : colors.get(value)
      );
      const outlineColor = hasVisibleBorders
        ? Color.fromHex(theme.outlineColor)
        : color;

      return new UniqueValueInfo({
        value,
        symbol: new SimpleFillSymbol({
          color,
          outline: { color: outlineColor },
        }),
      });
    };

    return new UniqueValueRenderer({
      field: CODE_FIELD,
      uniqueValueInfos: visibleCountries.map(createInfo),
    });
  },
});
