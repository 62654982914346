import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import { RulesForm } from "../../../forms/RulesForm";
import { useGameNotStarted } from "../../../hooks/game/useGameNotStarted";

export function NotStartedGame() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isResetDisabled, onReset, onStart } = useGameNotStarted();

  return (
    <Modal
      show
      onHide={() => navigate("/")}
      backdrop="static"
      fullscreen="sm-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("game.notStarted.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RulesForm autoFocus />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="danger"
          size="lg"
          disabled={isResetDisabled}
          onClick={onReset}
        >
          {t("app.reset")}
        </Button>
        <Button variant="primary" size="lg" onClick={() => onStart()}>
          {t("app.play")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
