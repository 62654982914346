import { useRecoilValue, useResetRecoilState } from "recoil";
import { useMemo } from "react";
import { isEqual } from "lodash";

import { rulesState } from "../../state/game/rules";
import { defaultRules } from "../../constants/rules";
import { useStartGame } from "./useStartGame";

export function useGameNotStarted() {
  const rules = useRecoilValue(rulesState);
  const onReset = useResetRecoilState(rulesState);
  const onStart = useStartGame();

  const isResetDisabled = useMemo(() => isEqual(rules, defaultRules), [rules]);

  return { isResetDisabled, onStart, onReset };
}
