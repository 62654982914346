import { atom, selector } from "recoil";

import { foundCodesState } from "../game/foundCodes";

export const areResultsSavingState = atom({
  key: "areResultsSavingState",
  default: false,
});

export const areResultsSavedState = atom({
  key: "areResultsSavedState",
  default: false,
});

export const areResultsSavableState = selector({
  key: "areResultsSavableState",
  get: ({ get }) => {
    const areResultsSaving = get(areResultsSavingState);
    const areResultsSaved = get(areResultsSavedState);
    const foundCodes = get(foundCodesState);

    return !areResultsSaving && !areResultsSaved && !!foundCodes.size;
  },
});

export const unsavedResultsState = atom({
  key: "unsavedResultsState",
  default: [],
});
