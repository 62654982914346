import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { ProfileBody } from "./ProfileBody";
import { ProfileHeader } from "./ProfileHeader";
import { useUserInfo } from "../../hooks/users/useUserInfo";

export function ProfileLoaded({ className, profile }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useUserInfo(profile.id);

  const onHide = useCallback(() => {
    const origin = location.state?.origin || "/";
    navigate(origin);
  }, [location.state?.origin, navigate]);

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      size="xl"
      fullscreen="xl-down"
      enforceFocus={false}
      className={className}
    >
      <Modal.Header closeButton>
        <ProfileHeader profile={profile} userInfo={userInfo} />
      </Modal.Header>
      <Modal.Body className="p-0">
        <ProfileBody profile={profile} userInfo={userInfo} />
      </Modal.Body>
    </Modal>
  );
}
