import { useTranslation } from "react-i18next";
import { Button, Card, Form, InputGroup, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { RoomLobbyPlayer } from "./RoomLobbyPlayer";
import { useCopy } from "../../../hooks/shared/useCopy";

export function RoomLobbyPlayers({ room, players }) {
  const { t } = useTranslation();
  const location = useLocation();
  const url = window.location.origin + location.pathname;
  const { hasCopied, onCopy } = useCopy(url);

  return (
    <>
      <Card className="w-100 mb-2">
        <Card.Header>{t("multi.rooms.lobby.players.header")}</Card.Header>
        <ListGroup variant="flush">
          {players.map((player) => (
            <RoomLobbyPlayer
              key={player.createdBy}
              room={room}
              player={player}
            />
          ))}
        </ListGroup>
      </Card>
      <Card bg="primary" className="text-bg-primary mb-2">
        <Card.Header>{t("multi.rooms.lobby.players.share.header")}</Card.Header>
        <Card.Body>
          <InputGroup>
            <Form.Control type="text" readOnly value={url} />
            <Button variant="dark" onClick={onCopy}>
              {hasCopied
                ? t("multi.rooms.lobby.players.copied")
                : t("multi.rooms.lobby.players.copy")}
            </Button>
          </InputGroup>
          <p className="mb-0">{t("multi.rooms.lobby.players.share.helper")}</p>
        </Card.Body>
      </Card>
    </>
  );
}
