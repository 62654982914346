import { Alert, Badge, Card, Form, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RulesFilterForm } from "../../forms/RulesFilterForm";
import { LeaderboardDropdown } from "../leaderboard/LeaderboardDropdown";
import { useStatistics } from "../../hooks/profiles/useStatistics";
import { ProfileUpgradeWarning } from "./ProfileUpgradeWarning";
import { Spinner } from "../shared/Spinner";
import { Statistics } from "./Statistics";
import { getAllContinentCodes } from "../../helpers/codes";

export function StatisticsTab({ profile }) {
  const { t } = useTranslation();
  const state = useStatistics(profile);

  return (
    <div className="StatisticsTab">
      <Card>
        <Card.Header>{t("app.filters")}</Card.Header>
        <Card.Body>
          <RulesFilterForm
            value={state.filters}
            onSelectChange={state.onSelectChange}
            onSwitchChange={state.onSwitchChange}
          />
        </Card.Body>
      </Card>
      <div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
        <LeaderboardDropdown
          className="w-50"
          value={state.leaderboardId}
          onChange={state.onLeaderboardIdChange}
        />
        {!!state.totalResults && (
          <ListGroup horizontal>
            <ListGroup.Item>
              <Badge pill>{state.totalResults}</Badge>{" "}
              {t("pages.profile.games", { count: state.totalResults })}
            </ListGroup.Item>
          </ListGroup>
        )}
        <Form.Check
          id="grouped"
          type="switch"
          className="ms-auto me-1 d-inline-block"
          label={t("pages.profile.statistics.groupByContinent")}
          checked={state.grouped}
          onChange={state.onGroupedChange}
        />
      </div>
      {!!state.error && (
        <Alert className="mt-3" variant="danger">
          {state.error.toString()}
        </Alert>
      )}
      <ProfileUpgradeWarning profile={profile} className="mt-3" />
      {state.isLoading && <Spinner className="my-5 py-5" />}
      {!state.isLoading && !state.items.length && (
        <p className="text-center my-5 py-5">
          {t("pages.profile.statistics.noGame")}
        </p>
      )}
      {!state.isLoading &&
        !!state.items.length &&
        (state.grouped ? (
          getAllContinentCodes(state.isExtendedMode).map((continentCode) => (
            <Card key={continentCode} className="mt-3">
              <Card.Header className="text-center">
                <strong>{t(`game.results.continents.${continentCode}`)}</strong>
              </Card.Header>
              <Card.Body>
                <Statistics
                  key={continentCode}
                  items={state.items.filter(
                    (item) => item.continentCode === continentCode
                  )}
                  locked={!profile.isUpgraded}
                />
              </Card.Body>
            </Card>
          ))
        ) : (
          <div className="mt-3">
            <Statistics items={state.items} locked={!profile.isUpgraded} />
          </div>
        ))}
    </div>
  );
}
