import { useRecoilValue, useResetRecoilState } from "recoil";
import { useMemo } from "react";
import { isEqual } from "lodash";

import { themeNameState } from "../../state/game/theme";
import { defaultThemeName } from "../../constants/themes";

export function useThemePicker() {
  const themeName = useRecoilValue(themeNameState);
  const onReset = useResetRecoilState(themeNameState);

  const isResetDisabled = useMemo(
    () => isEqual(themeName, defaultThemeName),
    [themeName]
  );

  return { isResetDisabled, onReset };
}
