import clsx from "clsx";

export function Title({ className = "text-light" }) {
  return (
    <h1 className={clsx("text-center text-decoration-underline", className)}>
      <small>www.</small>
      <strong>geoquiz</strong>
      <small>.live</small>
    </h1>
  );
}
