import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function RoomLobbyAlert({ admin, arePlayersReady }) {
  const { t } = useTranslation();

  if (!admin?.userInfo.isOnline) {
    return (
      <Alert variant="danger">{t("multi.rooms.lobby.adminRequired")}</Alert>
    );
  }

  if (!arePlayersReady) {
    return (
      <Alert variant="warning">{t("multi.rooms.lobby.playersRequired")}</Alert>
    );
  }

  return <Alert variant="success">{t("multi.rooms.lobby.canStart")}</Alert>;
}
