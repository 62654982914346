import { useMedia } from "react-use";
import { useCallback, useState } from "react";

export function useResponsiveRoomScores() {
  const isSmall = useMedia("(width < 1280px)");
  const [areScoresOpen, setAreScoresOpen] = useState(false);

  const onOpenScores = useCallback(() => {
    setAreScoresOpen(true);
  }, []);

  const onCloseScores = useCallback(() => {
    setAreScoresOpen(false);
  }, []);

  return { isSmall, areScoresOpen, onOpenScores, onCloseScores };
}
