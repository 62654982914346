import clsx from "clsx";

import "./Statistics.scss";

export function Statistics({ items, locked }) {
  return (
    <div
      className={clsx(
        "Statistics d-flex flex-wrap text-center p-1 gap-1",
        locked && "locked"
      )}
    >
      {items.map((item) => (
        <span
          key={item.code}
          className="Item py-1 px-2 flex-grow-1"
          style={{ backgroundColor: item.color }}
        >
          <span className={`fi fi-${item.flag}`} />{" "}
          <strong>{item.label}</strong> {(item.percentage * 100).toFixed(1)}%
        </span>
      ))}
    </div>
  );
}
