import { useBeforeUnload } from "react-use";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import Confetti from "react-confetti";

import { ResponsiveResults } from "../shared/ResponsiveResults";
import { areResultsSavableState } from "../../../state/users/results";
import { foundCodesState } from "../../../state/game/foundCodes";
import { allCodesState } from "../../../state/results/allCodes";
import { GameResult } from "./GameResult";

export function EndedGame({ actions = null }) {
  const { t } = useTranslation();
  const foundCodes = useRecoilValue(foundCodesState);
  const allCodes = useRecoilValue(allCodesState);
  const win = foundCodes.size === allCodes.size;
  const areResultsSavable = useRecoilValue(areResultsSavableState);
  useBeforeUnload(areResultsSavable, t("app.confirmationMessage"));

  return (
    <div className="EndedGame">
      <div className="AppContentTop">
        <GameResult actions={actions} />
      </div>
      <ResponsiveResults />
      {win && <Confetti />}
    </div>
  );
}
