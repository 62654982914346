import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { Language } from "./constants/language";

const getDefaultLanguage = () => {
  if (navigator.language.startsWith(Language.fr)) {
    return Language.fr;
  }

  if (navigator.language.startsWith(Language.es)) {
    return Language.es;
  }

  return Language.en;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getDefaultLanguage(),
    fallbackLng: "en",
    whitelist: Object.values(Language),
    debug: window.location.hostname === "localhost",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
