import { useCallback, useState } from "react";
import { useMedia } from "react-use";

export function useResponsiveResults() {
  const isSmall = useMedia("(width <= 916px)");
  const [areResultsOpen, setAreResultsOpen] = useState(false);

  const onOpenResults = useCallback(() => {
    setAreResultsOpen(true);
  }, []);

  const onCloseResults = useCallback(() => {
    setAreResultsOpen(false);
  }, []);

  return { isSmall, areResultsOpen, onOpenResults, onCloseResults };
}
