import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useRef } from "react";
import { onDisconnect, onValue, push, ref, set } from "firebase/database";
import { useLocation } from "react-router-dom";

import { database } from "../../firebase";
import { useTrackUserActivity } from "./useTrackUserActivity";

export function useTrackUserConnections(auth) {
  const [user] = useAuthState(auth);
  const connectionRef = useRef(null);
  const location = useLocation();
  const isActive = useTrackUserActivity();

  useEffect(() => {
    const uid = user?.uid;
    if (!uid) {
      return;
    }

    const connectionsRef = ref(database, `users/${uid}/connections`);
    const connectedRef = ref(database, ".info/connected");

    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        const connection = push(connectionsRef);
        connectionRef.current = connection;

        onDisconnect(connection).remove().catch(console.error);

        set(connection, {
          url: window.location.pathname,
          isActive: true,
        }).catch(console.error);
      }
    });
  }, [user?.uid]);

  useEffect(() => {
    if (connectionRef.current && user?.uid) {
      set(connectionRef.current, { url: location.pathname, isActive }).catch(
        console.error
      );
    }
  }, [user?.uid, location, isActive]);
}
