import { collection, doc, setDoc, updateDoc } from "firebase/firestore";

import { converter } from "../helpers/collections";
import { db } from "../firebase";

export const profilesCollection = collection(db, "profiles").withConverter(
  converter
);

export async function createProfile({ username, createdBy, isUpgraded }) {
  const now = Date.now();
  const data = {
    username,
    createdBy,
    isUpgraded,
    createdAt: now,
    updatedAt: now,
  };

  const ref = doc(profilesCollection, createdBy);
  await setDoc(ref, data);
  return { ...data, id: createdBy };
}

export async function updateProfile(userId, data) {
  const ref = doc(profilesCollection, userId);
  await updateDoc(ref, { ...data, updatedAt: Date.now() });
}
