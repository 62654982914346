import { Table } from "react-bootstrap";
import { useRecoilValue } from "recoil";

import { ScoreMedal } from "../../leaderboard/ScoreMedal";
import { Username } from "../../user/Username";
import { ElapsedTime } from "./ElapsedTime";
import { statusState } from "../../../state/game/status";
import { GameStatus } from "../../../constants/game";

import "../../leaderboard/ListScores.scss";

export function RoomScores({ room, players }) {
  return (
    <Table striped hover variant="light" className="ListScores align-middle">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Player</th>
          <th className="text-end">Score</th>
        </tr>
      </thead>
      <tbody>
        {players.map((player, index) => (
          <RoomScore
            key={player.id}
            room={room}
            player={player}
            rank={index + 1}
          />
        ))}
      </tbody>
    </Table>
  );
}

function RoomScore({ room, player, rank }) {
  const gameStatus = useRecoilValue(statusState);

  return (
    <tr>
      <td className="number col-sm-1 text-nowrap">
        {rank}
        {rank <= 3 && <ScoreMedal />}
      </td>
      <td className="pt-0 pb-1">
        <Username
          id={player.id}
          value={player.username}
          {...player.userInfo}
          clickable={gameStatus === GameStatus.ended}
        />
      </td>
      <td className="text-end number col-sm-1 text-nowrap">
        <span className="text-muted">
          <ElapsedTime
            startedAt={room.startedAt}
            endedAt={player.endedAt || room.endedAt}
            isLimited={room.endedAt !== false}
          />
        </span>{" "}
        {player.score}
      </td>
    </tr>
  );
}
