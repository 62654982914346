export function normalizeString(name) {
  return name
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f\\.]/g, "")
    .replace(/-/g, " ")
    .replace(/\s+/g, " ")
    .split(" ")
    .filter((word) => word !== "the")
    .join(" ");
}
