import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function Loading() {
  const { t } = useTranslation();

  return (
    <div className="AppContentTop">
      <Card bg="light">
        <Card.Body>
          <div className="float-end mt-1">
            <Spinner />
          </div>
          <Card.Text className="my-2">{t("app.isLoading")}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
