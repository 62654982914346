import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";

import {
  citiesLayerUrl,
  CITY_POPULATION_FIELD,
} from "../../../constants/globe";
import { citiesQueryState } from "../../../state/layers/cities/query";
import { sleep } from "../../../helpers/sleep";
import { citiesRendererState } from "../../../state/layers/cities/renderer";

export function useCitiesLayer(refs) {
  const citiesQuery = useRecoilValue(citiesQueryState);
  const citiesRenderer = useRecoilValue(citiesRendererState);
  const [isCitiesLayerReady, setCitiesLayerReady] = useState(false);

  useEffect(() => {
    refs.current.citiesLayer = new GeoJSONLayer({
      url: citiesLayerUrl,
      visible: false,
      orderBy: [{ field: CITY_POPULATION_FIELD, order: "descending" }],
    });

    refs.current.citiesLayer.on("layerview-create", () => {
      setCitiesLayerReady(true);
    });

    refs.current.map.add(refs.current.citiesLayer);
  }, [refs]);

  useEffect(() => {
    refs.current.citiesLayer.definitionExpression = citiesQuery;
  }, [citiesQuery, refs]);

  useEffect(() => {
    sleep(1).then(() => {
      refs.current.citiesLayer.renderer = citiesRenderer;
      refs.current.citiesLayer.visible = isCitiesLayerReady && !!citiesRenderer;
    });
  }, [citiesRenderer, isCitiesLayerReady, refs]);

  return { isCitiesLayerReady };
}
