import { useTranslation } from "react-i18next";

import { Room } from "../components/room/Room";
import { useDocumentTitle } from "../hooks/shared/useDocumentTitle";

export function RoomPage() {
  const { t } = useTranslation();
  useDocumentTitle(t("app.multiPlayer"));

  return <Room />;
}
