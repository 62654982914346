import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useAuthState } from "react-firebase-hooks/auth";

import { newRoomNameState } from "../../state/forms/newRoom";
import { auth } from "../../firebase";

export function useDefaultRoomName() {
  const [user] = useAuthState(auth);
  const [newRoomName, setNewRoomName] = useRecoilState(newRoomNameState);

  useEffect(() => {
    if (user && user.displayName && newRoomName === null) {
      const roomName = user.displayName.endsWith("s")
        ? `${user.displayName}' room`
        : `${user.displayName}'s room`;

      setNewRoomName(roomName);
    } else if (!user && newRoomName !== null) {
      setNewRoomName(null);
    }
  }, [newRoomName, setNewRoomName, user, user?.displayName]);
}
