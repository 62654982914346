import { useCallback } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { logEvent } from "firebase/analytics";

import { GameStatus } from "../../constants/game";
import { statusState } from "../../state/game/status";
import { endedAtState } from "../../state/game/endedAt";
import { inputState } from "../../state/game/input";
import { useSaveResults } from "../users/useSaveResults";
import { analytics } from "../../firebase";
import { startedAtState } from "../../state/game/startedAt";
import { getElapsedTime } from "../../helpers/timer";
import { rulesState } from "../../state/game/rules";
import { foundCodesState } from "../../state/game/foundCodes";
import { allCodesState } from "../../state/results/allCodes";

export function useOnStop() {
  const foundCodes = useRecoilValue(foundCodesState);
  const allCodes = useRecoilValue(allCodesState);
  const startedAt = useRecoilValue(startedAtState);
  const rules = useRecoilValue(rulesState);
  const setStatus = useSetRecoilState(statusState);
  const setEndedAt = useSetRecoilState(endedAtState);
  const resetInput = useResetRecoilState(inputState);
  const { saveResults } = useSaveResults();

  const onStop = useCallback(
    (matches = null) => {
      const endedAt = Date.now();
      setStatus(GameStatus.ended);
      setEndedAt(endedAt);
      resetInput();

      const finalFoundCodes = new Set(foundCodes);
      matches?.forEach((match) => finalFoundCodes.add(match));
      saveResults(endedAt, finalFoundCodes).catch(console.error);

      logEvent(analytics, "stop", {
        success: finalFoundCodes.size === allCodes.size,
        elapsedTime: getElapsedTime(startedAt, endedAt, rules.timer),
        totalFoundCodes: finalFoundCodes.size,
        totalCodes: allCodes.size,
      });
    },
    [
      setStatus,
      setEndedAt,
      resetInput,
      saveResults,
      foundCodes,
      allCodes.size,
      startedAt,
      rules.timer,
    ]
  );

  return { onStop };
}
