import { useRecoilState } from "recoil";

import { themeNameState } from "../../state/game/theme";

export function useThemeForm() {
  const [themeName, setThemeName] = useRecoilState(themeNameState);

  const onChange = (event) => {
    setThemeName(event.target.value);
  };

  return {
    themeName,
    onChange,
  };
}
