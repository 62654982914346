import { selector } from "recoil";

import { themeState } from "../game/theme";
import { rulesState } from "../game/rules";
import { ModeRule, DifficultyRule } from "../../constants/rules";
import { GameStatus } from "../../constants/game";
import {
  allCodes,
  allCountriesPerCode,
} from "../../constants/datasets/countries";
import { statusState } from "../game/status";
import { foundCodesState } from "../game/foundCodes";
import { activeCodeState } from "../game/activeCode";

export const colorsState = selector({
  key: "colorsState",
  get: ({ get }) => {
    const theme = get(themeState);
    const rules = get(rulesState);
    const gameStatus = get(statusState);
    const activeCode = get(activeCodeState);
    const foundCodes = get(foundCodesState);

    const isDifficultyEasy = rules.difficulty === DifficultyRule.visibleBorders;
    const hasGameEnded = gameStatus === GameStatus.ended;

    const getColor = (isActive, isFound) => {
      if (!isDifficultyEasy && !hasGameEnded) {
        return isFound ? theme.foundColor : theme.groundColor;
      }

      return isActive
        ? theme.activeColor
        : isFound
        ? theme.foundColor
        : hasGameEnded
        ? theme.missingColor
        : theme.groundColor;
    };

    return new Map(
      Array.from(allCodes).map((code) => {
        switch (rules.mode) {
          case ModeRule.extendedTerritories:
          case ModeRule.extendedCities: {
            const isActive = code === activeCode;
            const isFound = foundCodes.has(code);

            return [code, getColor(isActive, isFound)];
          }
          case ModeRule.mainCountries:
          case ModeRule.capitalCities: {
            const country = allCountriesPerCode.get(code);
            const parent = country.parent || null;
            const children = country.children || null;

            const isActive =
              code === activeCode ||
              (parent && activeCode === parent) ||
              (children && children.some((child) => child === activeCode));
            const isFound =
              foundCodes.has(code) ||
              (parent && foundCodes.has(parent)) ||
              (children && children.some((child) => foundCodes.has(child)));

            return [code, getColor(isActive, isFound)];
          }
          default:
            return [code, theme.groundColor];
        }
      })
    );
  },
});
