import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card } from "react-bootstrap";

export function SuccessPage({ title, message }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="AppContentTop">
      <Card bg="success" text="light">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <Button
            variant="light"
            className="float-end"
            onClick={() => navigate("/")}
          >
            {t("app.home")}
          </Button>
          <Card.Text>{message}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
